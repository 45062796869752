import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
//import { isTablet } from 'react-device-detect';
import Header from './Component/Header';
import Login from './Seguridad/Login';
import Inicio from './Administracion/Inicio';
import PersistedState from './Hook/PersistedState';
import { Constantes } from './Shared/Constantes';
import User from './Seguridad/User';
import HeaderAdmin from './Component/HeaderAdmin';
import Aerolineas from './Administracion/Aerolinea';
import Usuarios from './Seguridad/Usuario';
import Servicios from './Administracion/Servicios';
import Home from './Servicios/Inicio';
import HeaderOperador from './Component/HeaderOperador';
import Operacion from './Operacion/Inicio';
import Abordar from './Operacion/Abordar';
import Loader from './Component/Loader';

function App() {
  const [usuario] = PersistedState(Constantes.userStorage, new User());
  const [entrar, setEntrar] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Loader show={loading} />
      {!(usuario?.logueado ?? false) &&
        <Router>
          <Header entrar={setEntrar} eslogin={entrar} />
          <Routes>
            {entrar ?
              <Route exact path='/' element={<Login loading={setLoading} />} />
              :
              <>
                <Route exact path='/' element={<Home loading={setLoading} />} />
                <Route exact path='/search/:id' element={<Home loading={setLoading} />} />
              </>
            }
          </Routes>
        </Router>
      }
      {usuario?.logueado &&
        <Router>
          {usuario?.rol === '1' &&
            <>
              <HeaderAdmin />
              <Routes>
                <Route exact path='/home' element={<Inicio loading={setLoading} />} />
                <Route exact path='/servicios' element={<Servicios loading={setLoading} />} />
                <Route exact path='/aerolineas' element={<Aerolineas loading={setLoading} />} />
                <Route exact path='/usuarios' element={<Usuarios loading={setLoading} />} />
              </Routes>
            </>
          }
          {usuario?.rol === '2' &&
            <>
              <HeaderOperador />
              <Routes>
                <Route exact path='/home' element={<Operacion loading={setLoading} />} />
                <Route exact path='/abordar' element={<Abordar loading={setLoading} />} />
              </Routes>
            </>
          }
        </Router>
      }
      <footer>
        <div className="row m-2">
          <div className="col-lg-12 col-md-12 col-sm-12 pie">
            <label className="txt-title">Contactanos</label><br />
            <a href="mailto:traslados@ctcongresosyconvenciones.com.mx" className="btn-pie m-2 txt-link"><i className="pi pi-envelope"></i> traslados@ctcongresosyconvenciones.com.mx</a> <br />
            <a href="https://api.whatsapp.com/send?phone=528123507070" target="_new" className="btn-pie m-2 txt-link"><i className="pi pi-whatsapp"></i> 81 2350-7070</a><br />
            <a href="tel:8117461577" className="btn-pie m-2 txt-link"><i className="pi pi-mobile"></i> 81 1746-1577</a><br />
            <a href="tel:8147802370" className="btn-pie m-2 txt-link"><i className="pi pi-phone"></i> 814780-2370</a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
