/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import TrasladoAPI from "../../API/Servicios/TrasladoAPI";

const Eventos = ({ id, verDetalle, reload, read, confirmar, loading }) => {
    const [datos, setDatos] = useState([]);
    const api = TrasladoAPI();
    useEffect(() => {
        cargar();
    }, [reload]);

    const cargar = async () => {
        loading(true);
        let result = await api.traslados(id);
        result.items.map((item) => (
            item.servicio = item.id_servicio === '1' ? 'Traslados' : 'Tour'
        ));
        console.log(result.items);
        setDatos(result.items);
        loading(false);
    };

    const ver = (item) => {
        verDetalle(item)
    }

    const templateEditButton = (item) => {
        return (
            <div className="botones" style={{ textAlign: 'end' }}>
                <Button type="button" icon="pi pi-pencil" onClick={() => ver(item)} className="btn-azul" />
            </div>
        );
    };

    const header = (
        <div style={{ textAlign: 'end' }}>
            {id !== 7 &&
                <div className="botones">
                    <Button icon="pi pi-whatsapp" className="btn-verde m-1"
                        onClick={() => confirmar(3, id)} />
                    <Button icon="pi pi-send" className="btn-morado m-1"
                        onClick={() => confirmar(4, id)} />
                </div>
            }
        </div>
    );

    const templateBool = (item) => (
        <>
            {item.requiere_factura === '1' ? 'SI' : 'NO'}
        </>
    )

    return (
        <DataTable value={datos} responsiveLayout="scroll"
            paginator={true} rows={10} emptyMessage="Sin datos para mostrar"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]} >
            <Column field="folio" header="Folio" className="center" />
            <Column field="nombre" header="Nombre" />
            <Column field="servicio" header="Servicio" />
            <Column field="tipo" header="Tipo de Traslado" />
            <Column field="evento" header="Evento" />
            <Column field="tipo" header="Traslado" />
            <Column field="adultos" header="Pasajeros" className="center" />
            <Column field="menores" header="Menores" className="center" />
            <Column field="requiere_factura" header="Facturar" body={templateBool} className="center" />
            {!read &&
                <Column body={templateEditButton} header={header} headerStyle={{ width: 150 }} />
            }
        </DataTable>);
};

export default Eventos;