/* eslint-disable react-hooks/exhaustive-deps */
import produce from "immer";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import TrasladoAPI from "../../API/Servicios/TrasladoAPI";
import Combo from "../../Component/Combo";

const Operaciones = ({ id, reload, loading }) => {
    const [datos, setDatos] = useState([]);
    const api = TrasladoAPI();
    const [horaIda, setHoraIda] = useState([]);
    const [horaVuelta, setHoraVuelta] = useState([]);
    const [fechaIda, setFechaIda] = useState([]);
    const [fechaVuelta, setFechaVuelta] = useState([]);
    const [filtro, setFiltro] = useState({
        fechaIda: '',
        horaIda: '',
        fechaVuelta: '',
        horaVuelta: ''
    });
    useEffect(() => {
        cargar(filtro);
    }, [reload]);

    const cargar = async (params) => {
        loading(true);
        let result = await api.abordaje(id, params);
        let listHoraIda = [{ label: 'Todos', value: '' }];
        let listHoraVuelta = [{ label: 'Todos', value: '' }];
        let listFechaIda = [{ label: 'Todos', value: '' }];
        let listFechaVuelta = [{ label: 'Todos', value: '' }];
        result.items.forEach((item) => {
            item.servicio = item.id_servicio === '1' ? 'Traslados' : 'Tour';
            item.fecha_hora_ida = item.hora_ida === null ? '' : moment(new Date(item.fecha_ida + ' ' + item.hora_ida)).format('DD/MM/yyyy LT');
            item.fecha_hora_vuelta = item.hora_vuelta === null ? '' : moment(new Date(item.fecha_vuelta + ' ' + item.hora_vuelta)).format('DD/MM/yyyy LT');

            if (item.fecha_ida !== null) {
                if (!listFechaIda.some(l => l.value === item.fecha_ida)) {
                    listFechaIda.push({ label: item.fecha_ida, value: item.fecha_ida });
                }
            }

            if (item.fecha_vuelta !== null) {
                if (!listFechaVuelta.some(l => l.value === item.fecha_vuelta)) {
                    listFechaVuelta.push({ label: item.fecha_vuelta, value: item.fecha_vuelta });
                }
            }

            if (item.hora_ida !== null) {
                if (!listHoraIda.some(l => l.value === item.hora_ida)) {
                    listHoraIda.push({ label: item.hora_ida, value: item.hora_ida });
                }
            }

            if (item.hora_vuelta !== '') {
                if (!listHoraVuelta.some(l => l.value === item.hora_vuelta)) {
                    listHoraVuelta.push({ label: item.hora_vuelta, value: item.hora_vuelta });
                }
            }
        });

        setFechaIda(listFechaIda);
        setFechaVuelta(listFechaVuelta);
        setHoraIda(listHoraIda);
        setHoraVuelta(listHoraVuelta);

        setDatos(result.items);
        loading(false);
    };

    const actualizar = async (value, propertyName) => {
        const newValue = produce(filtro, (draft) => {
            draft[propertyName] = value;
        });

        setFiltro(newValue);

        cargar(newValue);
    };

    const filtrosTemplate = () => {
        return (
            <div className="row">
                <Combo label={'Fecha Llegada'} name="fechaIda" value={filtro?.fechaIda}
                    onChange={actualizar} opciones={fechaIda} lg={3} md={3} sm={12} />
                <Combo label={'Hora Llegada'} name="horaIda" value={filtro?.horaIda}
                    onChange={actualizar} opciones={horaIda} lg={3} md={3} sm={12} />
                <Combo label={'Fecha Regreso'} name="fechaVuelta" value={filtro?.fechaVuelta}
                    onChange={actualizar} opciones={fechaVuelta} lg={3} md={3} sm={12} />
                <Combo label={'Hora Regreso'} name="horaVuelta" value={filtro?.horaVuelta}
                    onChange={actualizar} opciones={horaVuelta} lg={3} md={3} sm={12} />
            </div>
        );
    };

    return (
        <DataTable value={datos} responsiveLayout="scroll" header={filtrosTemplate}
            paginator={true} rows={10} emptyMessage="Sin datos para mostrar"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}>
            <Column field="folio" header="Folio" className="center" />
            <Column field="servicio" header="Servicio" />
            <Column field="evento" header="Evento" />
            <Column field="tipo" header="Traslado" />
            <Column field="nombre" header="Pasajero" />
            <Column field="fecha_hora_ida" header="Transporte Llegada" />
            <Column field="fecha_hora_vuelta" header="Transporte Regreso" />
        </DataTable>
    );
};

export default Operaciones;