/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import AerolineaAPI from "../API/Catalogo/AerolineaAPI";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import DetalleAerolinea from "./DetalleAerolinea";
import { Toast } from "primereact/toast";

const Aerolineas = ({ loading }) => {
    const api = AerolineaAPI();
    const [datos, setDatos] = useState([]);
    const [verDetalle, setVerDetalle] = useState(false);
    const [aerolinea, setAerolinea] = useState({});
    const toastBR = useRef(null);

    useEffect(() => {
        cargar();
    }, []);

    const cargar = async () => {
        loading(true);
        const result = await api.obtener();
        setDatos(result.items);
        loading(false);
    };

    const ver = (item) => {
        setAerolinea(item);
        setVerDetalle(true);
    }

    const templateHeaderButton = () => {
        return (
            <div className="botones">
                <Button type="button" icon="pi pi-plus" onClick={() => ver({ id_aerolinea: 0 })} className="btn-azul" />
            </div>
        );
    }

    const templateEditButton = (item) => {
        return (
            <div className="botones">
                <Button type="button" icon="pi pi-pencil" onClick={() => ver(item)} className="btn-azul" />
            </div>
        );
    };

    const templateColumn = (item) => {
        const activo = item.activo === '1' ? 'SI' : 'NO';
        return (
            <span>{activo}</span>
        );
    };

    const cerrar = async () => {
        showBottomRight();
        setVerDetalle(false);
        await cargar();
    }

    const showBottomRight = () => {
        toastBR.current.show({ severity: 'success', summary: 'Operacion Exitosa', detail: 'Operacion realizada correctamente.', life: 3000 });
    }

    return (
        <div className="container container-min">
            <h5>Aerolineas</h5>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <DataTable value={datos} responsiveLayout="scroll"
                        paginator={true} rows={10} emptyMessage="Sin datos para mostrar"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}>
                        <Column field="id_aerolinea" header="ID" className="center" />
                        <Column field="nombre" header="Aerolinea" />
                        <Column body={templateColumn} header="Activo" />
                        <Column body={templateEditButton} header={templateHeaderButton} style={{ width: 100 }} />
                    </DataTable>
                </div>
            </div>
            <Sidebar visible={verDetalle} position="right" fullScreen className="p-sidebar-lg"
                onHide={() => setVerDetalle(false)} dismissable={false}>
                <DetalleAerolinea item={aerolinea} cerrar={cerrar} loading={loading} />
            </Sidebar>
            <Toast ref={toastBR} position="bottom-right" />
        </div>
    );
};

export default Aerolineas;