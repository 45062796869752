import * as yup from "yup";

const guardarEvento = yup.object().shape({
    nombre: yup.string().required(),
    lugar: yup.string().required(),
    hotel: yup.string().required(),
    dia_inicio: yup.number().required(),
    mes_inicio: yup.mixed().required(),
    dia_fin: yup.number().required(),
    mes_fin: yup.number().required(),
    anio: yup.number().required(),
    transporte: yup.array().of(
        yup.object().shape({
            id_tipo_traslado: yup.number().required(),
            nombre: yup.string().required(),
            precio: yup.number().required(),
            horario: yup.array().of(
                yup.object().shape({
                    id_tipo_traslado: yup.number().required(),
                    //fecha_hora: yup.mixed().required()
                })
            )
        })
    )
});

const guardarTour = yup.object().shape({
    nombre: yup.string().required(),
    dia_inicio: yup.number().required(),
    mes_inicio: yup.mixed().required(),
    dia_fin: yup.number().required(),
    mes_fin: yup.number().required(),
    anio: yup.number().required(),
    transporte: yup.array().of(
        yup.object().shape({
            id_tipo_traslado: yup.number().required(),
            nombre: yup.string().required(),
            precio: yup.number().required(),
            horario: yup.array().of(
                yup.object().shape({
                    id_tipo_traslado: yup.number().required(),
                    //fecha_hora: yup.mixed().required()
                })
            )
        })
    )
});

const EventoValidacion = {
    guardarEvento,
    guardarTour
};

export default EventoValidacion;
