import React from "react";
import "./spinner.css"
import { ProgressSpinner } from "primereact/progressspinner";

const Loader = ({ show }) => {
    return (
        <>
            {(show) &&
                <div id="main-overlay-panel" style={{ zIndex: 998 }}>
                    <div className="loading-overlay" style={{ zIndex: 999 }}>

                    </div>
                    <div id="main-loading-container" className="loading-container" style={{ zIndex: 999 }}>
                        <div>
                            <ProgressSpinner />
                        </div>
                        <h2>Cargando...</h2>
                    </div>
                </div>
            }
        </>
    );
};

export default Loader;