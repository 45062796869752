import ServiceFactory from "./ServiceFactory";
const controller = "/apiNotificacion";

const NotificacionAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        createMessage: async (id, params) => service.send(`${controller}/createMessage/${id}`, params),
        enviarCorreo: async (id, params) => service.post(`${controller}/enviarCorreo/${id}`, params),
        enviarCorreoMasivo: async (id, params) => service.post(`${controller}/enviarCorreoMasivo/${id}`, params),
        createMessageMasivo: async (id, params) => service.send(`${controller}/createMessageMasivo/${id}`, params)
    }
};

export default NotificacionAPI;