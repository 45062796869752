import ServiceFactory from "../ServiceFactory";
const controller = "/apiEvento";

const EventoAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        obtener: async (id) => service.get(`${controller}/obtener/${id}`),
        detalle: async (id) => service.get(`${controller}/detalle/${id}`),
        guardar: async (params) => service.post(`${controller}/guardar`, params),
        eliminar: async (id) => service.delete(`${controller}/eliminar/${id}`),
        eliminarTransporte: async (id) => service.delete(`${controller}/eliminarTransporte/${id}`),
        eliminarHorario: async (id) => service.delete(`${controller}/eliminarHorario/${id}`)
    }
};

export default EventoAPI;