import React, { useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';

const Radio = ({ name, label, value, onChange, lg, md, sm, requerido, opciones }) => {
    const [selected, setSelected] = useState(null);
    const change = (e) => {
        const value = e.value;
        setSelected(e.value);
        onChange(value, name);
    };

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-row">
                {(opciones ?? null) === null ?
                    <>
                        <RadioButton inputId={name} name={name} value={value} onChange={change}
                            className={requerido && ((value ?? null) === null) ? 'p-invalid block' : ''} />
                        <label htmlFor={name}>{label}</label>
                    </>
                    :
                    <>
                        {opciones?.map((item, index) => {
                            return (
                                <div key={index} className="field-radiobutton" style={{ marginLeft: 10 }}>
                                    <RadioButton inputId={item.id} name={name} value={item} onChange={change} checked={selected?.id === item.id} />
                                    <label htmlFor={item.id}>{item.nombre}</label>
                                </div>
                            );
                        })}

                    </>
                }
            </div>
        </div>
    );
};

export default Radio;