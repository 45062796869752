
import ServiceFactory from "../ServiceFactory";
const controller = "/apiExportar";

const ExportarAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        exportar: async () => service.get(`${controller}/exportar`),
        descargar: async () => service.get(`${controller}/descargar`)
    }
};

export default ExportarAPI;
