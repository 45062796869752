import { Sidebar } from "primereact/sidebar";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useRef, useState } from "react";
import Eventos from "../Shared/Control/TableEventos";
import DetalleInicio from "./DetalleInicio";
import { Toast } from "primereact/toast";
import ExportarAPI from "../API/Servicios/ExportarAPI";
import { Button } from "primereact/button";
import { saveAs } from 'file-saver';
import { Dialog } from 'primereact/dialog';
import TextArea from "../Component/TextArea";
import NotificacionAPI from "../API/NotificacionAPI";

const Inicio = ({ loading }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [ver, setVer] = useState(false);
    const [evento, setEvento] = useState({});
    const toastBR = useRef(null);
    const [reload, setReload] = useState(false);
    const api = ExportarAPI();
    const [editarMsg, setEditarMsg] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [tipo, setTipo] = useState(0);
    const [estatus, setEstatus] = useState(0);
    const notificar = NotificacionAPI();
    const [enviado, setEnviado] = useState(false);
    const [maxLength, setMaxLength] = useState(50);

    const verDetalle = (item) => {
        setMensaje('');
        setReload(false);
        setEvento(item);
        setVer(true);
    };

    const showBottomRight = () => {
        toastBR.current.show({ severity: 'success', summary: 'Operacion Exitosa', detail: 'Operacion realizada correctamente.', life: 3000 });
    }

    const exportar = async () => {
        setMensaje('');
        loading(true);
        const result = await api.exportar();
        saveAs(result?.file, 'Pasajeros');
        loading(false);
    };

    const confirmar = (id, estatus) => {
        setMaxLength(id === 3 ? 50 : 250);
        setMensaje('');
        setTipo(id)
        setEstatus(estatus);
        setEditarMsg(true);
    }

    const enviar = async () => {
        setEnviado(true);
        loading(true);

        if (tipo === 3) {
            const send = await notificar.createMessageMasivo(estatus, { mensaje: mensaje });
            // if (send?.result) {
            setMensaje('');
            setEditarMsg(false);
            showBottomRight();
            // }
        }

        if (tipo === 4) {
            const send = await notificar.enviarCorreoMasivo(estatus, { mensaje: mensaje });
            // if (send?.result) {
            setMensaje('');
            setEditarMsg(false);
            showBottomRight();
            // }
        }

        loading(false);
    };

    const renderFooter = () => {
        return (
            <div className="botones">
                <Button label="Cancelar" icon="pi pi-times" onClick={() => setEditarMsg(false)} className="p-button-text" />
                <Button label="Enviar" icon="pi pi-check" onClick={enviar} autoFocus className="btn-azul" />
            </div>
        );
    };

    const actualizarMsg = (value, name) => {
        setMensaje(value);
    };

    return (
        <>
            <div className="container container-min">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <h5>Servicios de Traslados y Tours</h5>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 botones" style={{ textAlign: 'end' }}>
                        <Button label="Exportar" icon="pi pi-file-excel" position="right" className="btn-verde m-1"
                            onClick={exportar} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <TabView activeIndex={activeIndex} onChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="Por Pagar">
                                <Eventos id={1} verDetalle={verDetalle} reload={reload} confirmar={confirmar} loading={loading} />
                            </TabPanel>
                            <TabPanel header="En Proceso">
                                <Eventos id={2} verDetalle={verDetalle} reload={reload} confirmar={confirmar} loading={loading} />
                            </TabPanel>
                            <TabPanel header="Pagado">
                                <Eventos id={3} verDetalle={verDetalle} reload={reload} confirmar={confirmar} loading={loading} />
                            </TabPanel>
                            <TabPanel header="Rechazado">
                                <Eventos id={4} verDetalle={verDetalle} reload={reload} confirmar={confirmar} loading={loading} />
                            </TabPanel>
                            <TabPanel header="Abordado">
                                <Eventos id={7} verDetalle={verDetalle} reload={reload} confirmar={confirmar} loading={loading} />
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
                <Sidebar visible={ver} fullScreen position="right" className="p-sidebar-lg" onHide={() => setVer(false)} dismissable={false}>
                    <DetalleInicio item={evento} cerrar={() => setVer(false)} onSave={showBottomRight} reload={() => setReload(true)} loading={loading} />
                </Sidebar>
                <Dialog header="Editar mensaje" visible={editarMsg} style={{ width: '75vw' }}
                    onHide={() => setEditarMsg(false)} footer={renderFooter}>
                    <div className="row">
                        <TextArea label={'Mensaje'} value={mensaje} name="mensaje" lg={12} md={12} sm={12}
                            onChange={actualizarMsg} requerido={enviado} maxLength={maxLength} />
                    </div>
                </Dialog>
                <Toast ref={toastBR} position="bottom-right" style={{ zIndex: 999 }} />
            </div>
        </>
    );
};

export default Inicio;