import React from "react";
import { Dropdown } from "primereact/dropdown";

const Combo = ({ name, label, value, onChange, lg, md, sm, opciones, nameLabel, nameValue, requerido, disabled }) => {
    const change = (e) => {
        e.preventDefault();
        const value = e.value;
        const name = e.target.id;
        onChange(value, name);
    };

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-column">
                <label htmlFor={name} className='etiquetas'>
                    {requerido &&
                        <span style={{ color: 'red' }}>* </span>
                    }
                    {label}
                </label>
                <Dropdown name={name} value={value} id={name} options={opciones}
                    optionLabel={nameLabel} optionValue={nameValue}
                    onChange={change} className={requerido && ((value ?? null) === null) ? 'p-invalid block' : ''}
                    disabled={disabled} emptyMessage="Sin información para mostrar." />
            </div>
        </div>
    );
};

export default Combo;