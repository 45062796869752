/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import SeguridadAPI from "../API/Seguridad/SeguridadAPI";
import DetalleUsuario from "./DetalleUsuario";

const Usuarios = ({ loading }) => {
    const api = SeguridadAPI();
    const [datos, setDatos] = useState([]);
    const [verDetalle, setVerDetalle] = useState(false);
    const [usuario, setUsuario] = useState({});
    const toastBR = useRef(null);

    useEffect(() => {
        cargar();
    }, []);

    const cargar = async () => {
        loading(true);
        const result = await api.obtener();
        console.log(result);
        setDatos(result.items);
        loading(false);
    };

    const ver = (item) => {
        setUsuario(item);
        setVerDetalle(true);
    };

    const templateEditButton = (item) => {
        return (
            <div className="botones">
                <Button type="button" icon="pi pi-pencil" onClick={() => ver(item)} className="btn-azul" />
            </div>
        );
    };

    const templateHeaderButton = () => {
        return (
            <div className="botones">
                <Button type="button" icon="pi pi-plus" onClick={() => ver({ id_usuario: 0 })} className="btn-azul" />
            </div>
        );
    };

    const showBottomRight = () => {
        toastBR.current.show({ severity: 'success', summary: 'Operacion Exitosa', detail: 'Operacion realizada correctamente.', life: 3000 });
    };

    const cerrar = async () => {
        showBottomRight();
        setVerDetalle(false);
        await cargar();
    };

    return (
        <div className="container container-min">
            <div className="row">
                <h5>Eventos</h5>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <DataTable value={datos} responsiveLayout="scroll"
                        paginator={true} rows={10} emptyMessage="Sin datos para mostrar"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}>
                        <Column field="nombre" header="Nombre" />
                        <Column field="correo" header="Correo" />
                        <Column field="rol" header="Perfil" />
                        <Column body={templateEditButton} header={templateHeaderButton} />
                    </DataTable>
                </div>
            </div>
            <Sidebar visible={verDetalle} position="right" fullScreen className="p-sidebar-lg"
                onHide={() => setVerDetalle(false)} dismissable={false}>
                <DetalleUsuario item={usuario} save={cerrar} cerrar={() => setVerDetalle(false)} loading={loading} />
            </Sidebar>
            <Toast ref={toastBR} position="bottom-right" />
        </div>
    );
};

export default Usuarios;