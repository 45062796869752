import { Button } from "primereact/button";
import React, { useState } from "react";
import ExportarAPI from "../API/Servicios/ExportarAPI";
import { TabPanel, TabView } from "primereact/tabview";
import { saveAs } from "file-saver";
import Operaciones from "../Shared/Control/TableOperacion";

const Operacion = ({ loading }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const api = ExportarAPI();

    const exportar = async () => {
        loading(true);
        const result = await api.descargar();
        saveAs(result?.file, 'Abordaje');
        loading(false);
    };

    return (
        <div className="container container-min">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <h5>Servicios de Traslados y Tours</h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 botones" style={{ textAlign: 'end' }}>
                    <Button label="Exportar" icon="pi pi-file-excel" position="right" className="btn-verde m-1"
                        onClick={exportar} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <TabView activeIndex={activeIndex} onChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Pagado">
                            <Operaciones id={3} reload={false} read={true} loading={loading} />
                        </TabPanel>
                        <TabPanel header="Abordado">
                            <Operaciones id={7} reload={false} read={true} loading={loading} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
};

export default Operacion;