import React from "react";
import PersistedState from "../Hook/PersistedState";
import User from "../Seguridad/User";
import { Link } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { Constantes } from '../Shared/Constantes';
import { Button } from "primereact/button";

const HeaderOperador = () => {
    const [, setUsuario] = PersistedState(Constantes.userStorage, new User());

    const logout = () => {
        setUsuario(new User());
        window.location.reload(true);
    };

    const items = [
        {
            label: 'Traslados',
            template: (item, options) => {
                return (
                    <Link to="/home" target={item.target} className={options.className}>
                        <span><i className="pi pi-truck" /> {item.label}</span>
                    </Link>
                );
            }
        },
        {
            label: 'Abordar',
            template: (item, options) => {
                return (
                    <Link to="/abordar" target={item.target} className={options.className}>
                        <span><i className="pi pi-user" /> {item.label}</span>
                    </Link>
                );
            }
        }
    ];

    return (
        <header className="encabezado">
            <div className="form-row d-flex d-flex-column">
                <div className="col-lg-4 col-md-4 col-sm-12" style={{ padding: '10px' }}>
                    <img src={require('../images/backgroud_transparente_blanco.png')} className="logo-transparente-blanco" alt="CT Congresos & Convenciones" />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                    <div className="botones m-1" style={{ textAlign: 'end' }}>
                        <a href="/#" style={{ textDecoration: 'none' }}>
                            <Button icon="pi pi-sign-out" className="btn-text-morado"
                                onClick={logout} />
                        </a>
                    </div>
                    <h1 className="text-center mb-2">Bienvenido</h1>
                </div>
            </div>
            <Menubar model={items} className="mnu-azul" />
        </header>
    );
};

export default HeaderOperador;