import * as yup from "yup";

const guardar = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    id_rol: yup.mixed().required()
});

const UsuarioValidacion = {
    guardar
};

export default UsuarioValidacion;