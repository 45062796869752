
export const Constantes = {
    userStorage: 'userCongresosConvenciones',
    traslado: {
        LLEGADA: '1',
        REGRESO: '2',
        REDONDO: '3'
    },
    servicio: {
        Evento: 1,
        Tour: 2
    },
    metodo: {
        Tarjeta: '1',
        Transferencia: '2',
        Deposito: '3'
    },
    estatus: {
        PorPagar: '1',
        EnProceso: '2',
        Pagado: '3',
        ComprobanteRechazado: '4',
        PagoRechazado: '5',
        Cancelado: '6',
        Abordado: '7'
    }
}