//import { useLoadingDispatch } from "../Component/LoadingContext";

function ServiceFactory(optios = {}) {
    const urlBase = (optios && optios.uri) || process.env.REACT_APP_API_URL;

    const service = {
        get: async (urlAPI) => {
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'GET'
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });

            return result;
        },
        post: async (urlAPI, params) => {
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    return error;
                });

            return result;
        },
        put: async (urlAPI, params) => {
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'PUT',
                body: JSON.stringify(params),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    return error;
                });

            return result;
        },
        delete: async (urlAPI) => {
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'DELETE'
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });

            return result;
        },
        upload: async (urlAPI, formData) => {
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'POST',
                body: formData
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    return error;
                });

            return result;
        },
        send: async (urlAPI, params) => {
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    return error;
                });

            return result;
        }
    };

    return service;
};

export default ServiceFactory;