import * as yup from "yup";

const guardarRedondo = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    fecha_ida: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            vuelo_ida: yup.string().required(),
            vuelo_vuelta: yup.string().required(),
            id_hora_ida: yup.mixed().required(),
            id_hora_vuelta: yup.mixed().required(),
            id_aerolinea_ida: yup.number().required(),
            id_aerolinea_vuelta: yup.number().required()
        })
    ),
    id_metodo: yup.number().required(),
    comentario: yup.string().required()
});

const guardarIda = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    fecha_ida: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            vuelo_ida: yup.string().required(),
            id_hora_ida: yup.mixed().required(),
            id_aerolinea_ida: yup.number().required()
        })
    ),
    id_metodo: yup.number().required(),
    comentario: yup.string().required()
});

const guardarVuelta = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            vuelo_vuelta: yup.string().required(),
            id_hora_vuelta: yup.mixed().required(),
            id_aerolinea_vuelta: yup.number().required()
        })
    ),
    id_metodo: yup.number().required(),
    comentario: yup.string().required()
});

const guardarRedondoTour = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    fecha_ida: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            id_hora_ida: yup.mixed().required(),
            id_hora_vuelta: yup.mixed().required(),
        })
    ),
    id_metodo: yup.number().required(),
    comentario: yup.string().required()
});

const guardarIdaTour = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    fecha_ida: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            id_hora_ida: yup.mixed().required(),
        })
    ),
    id_metodo: yup.number().required(),
    comentario: yup.string().required()
});

const guardarVueltaTour = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            id_hora_vuelta: yup.mixed().required(),
        })
    ),
    id_metodo: yup.number().required(),
    comentario: yup.string().required()
});


const DetalleValidacion = {
    guardarRedondo,
    guardarVuelta,
    guardarIda,
    guardarIdaTour,
    guardarRedondoTour,
    guardarVueltaTour
};

export default DetalleValidacion;