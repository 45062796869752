import React from "react";
import { InputText } from 'primereact/inputtext';

const TextBox = ({ name, label, value, onChange, lg, md, sm, type, requerido, placeholder, disabled, maxLength }) => {
    const change = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        onChange(value, name);
    };

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-column">
                <label htmlFor={name} className='etiquetas'>
                    {requerido &&
                        <span style={{ color: 'red' }}>* </span>
                    }
                    {label}
                </label>
                <InputText type={type} id={name} name={name} value={value} placeholder={placeholder} disabled={disabled} maxLength={maxLength}
                    onChange={change} className={requerido && ((value ?? '') === '' || value === null) ? 'p-invalid block' : ''} />
            </div>
        </div>
    );
};

export default TextBox;