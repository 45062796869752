import React, { useRef, useState } from "react";
import QrReader from "react-qr-reader";
import OperacionAPI from "../API/Operacion/OperacionAPI";
import Etiqueta from "../Shared/Control/EtiquetaEvento";
import moment from "moment";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import produce from "immer";
import Combo from "../Component/Combo";
import { Divider } from "primereact/divider";

const Abordar = ({ loading }) => {
    const [data, setData] = useState('No result');
    const [apagar, setApagar] = useState(true);
    const api = OperacionAPI();
    const toastBR = useRef(null);
    const [selected, setSelected] = useState("environment");
    const opciones = [
        { value: 'environment', label: 'Camara Trasera' },
        { value: 'user', label: 'Camara Delatera' }
    ];
    const fecha = moment(new Date()).format("YYYY-MM-DD");

    const handleScan = async (data) => {
        loading(true);
        if (data !== null) {
            setApagar(true);
            console.log(data);
            const result = await api.cargar(data);

            console.log(result);
            setData(result);
        }
        loading(false);
    };

    const handleError = (err) => {
        console.error(err)
    };

    const abordar = async (id, tipo, index) => {
        loading(true);
        const result = await api.abordar(id, tipo);
        if (result?.id > 0) {
            const newValue = produce(data?.pasajeros, (draft) => {
                if (tipo === 1) {
                    draft[index]['id_estatus_ida'] = '7';
                }

                if (tipo === 2) {
                    draft[index]['id_estatus_vuelta'] = '7';
                }
            });


            setData({ ...data, pasajeros: newValue });
            showBottomRight();
        }
        loading(false);
    };

    const showBottomRight = () => {
        toastBR.current.show({ severity: 'success', summary: 'Operacion Exitosa', detail: 'Operacion realizada correctamente.', life: 3000 });
    };

    return (
        <div className="container container-min">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h5>Abordaje</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="botones">
                        <Button label={apagar ? 'Escanear Codigo' : 'Parar Escaner'} icon="pi pi-qrcode" position="right"
                            onClick={() => setApagar(!apagar)} className="btn-azul m-1" />
                    </div>
                </div>
            </div>
            <div className="row">
                {!apagar &&
                    <>
                        <Combo label={'Camara'} name="selected" value={selected} lg={12} md={12} skm={12}
                            opciones={opciones} onChange={(value, name) => setSelected(value)} />
                        <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                            <QrReader
                                delay={1000}
                                style={{ width: '100%' }}
                                onError={handleError}
                                onScan={handleScan}
                                facingMode={selected}
                            />
                        </div>
                    </>
                }
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    {data?.pasajeros?.map((item, index) => {
                        return (
                            <div className="card mt-2 p-2" key={index}>
                                <div className="row">
                                    <Etiqueta label={'Nombre'} span={item.nombre} lg={12} md={12} sm={12} />
                                    {(data?.id_tipo_traslado === '1' || data?.id_tipo_traslado === '3') &&
                                        <>
                                            <Divider />
                                            <h5>
                                                Llegada
                                                {item.id_estatus_ida === '7' &&
                                                    <i className="pi pi-check-circle" style={{ color: '#93D500', margin: 2 }}></i>
                                                }                                                
                                            </h5>
                                            <Etiqueta label={'Fecha de Abordaje'} span={moment(data?.fecha_ida).format("DD/MM/YYYY")} lg={6} md={6} sm={12} />
                                            <Etiqueta label={'Hora de Abordaje'} span={moment(new Date(`${data?.fecha_ida} ${item?.hora_ida}`)).format("LT")}
                                                lg={6} md={6} sm={12} />
                                            {item?.id_estatus_ida === null && data?.fecha_ida === fecha &&
                                                <div className="col-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                                                    <Button label="Abordar" icon="pi pi-thumbs-up" position="right" className="btn-verde m-1"
                                                        onClick={() => abordar(item.id_pasajero, 1, index)} />
                                                </div>
                                            }
                                        </>
                                    }
                                    {(data?.id_tipo_traslado === '2' || data?.id_tipo_traslado === '3') &&
                                        <>
                                            <Divider />
                                            <h5>
                                                Regreso
                                                {item.id_estatus_vuelta === '7' &&
                                                    <i className="pi pi-check-circle" style={{ color: '#93D500', margin: 2 }}></i>
                                                }
                                            </h5>
                                            <Etiqueta label={'Fecha de Abordaje'} span={moment(data?.fecha_vuelta).format("DD/MM/YYYY")} lg={6} md={6} sm={12} />
                                            <Etiqueta label={'Hora de Abordaje'} span={moment(new Date(`${data?.fecha_vuelta} ${item?.hora_vuelta}`)).format("LT")}
                                                lg={6} md={6} sm={12} />
                                            {item?.id_estatus_ida !== null && item?.id_estatus_vuelta === null && data?.fecha_vuelta === fecha &&
                                                <div className="col-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                                                    <Button label="Abordar" icon="pi pi-thumbs-up" position="right" className="btn-verde m-1"
                                                        onClick={() => abordar(item.id_pasajero, 2, index)} />
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Toast ref={toastBR} position="bottom-right" style={{ zIndex: 999 }} />
        </div>
    );
};

export default Abordar;