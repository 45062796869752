import React from "react";

const EtiquetaCosto = ({ label, span, lg, md, sm }) => {
    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className={'form-group d-flex flex-row'} style={{alignItems: 'end'}}>
                <label className="etiquetas">{label}</label><span style={{ width: '200px', textAlign: 'end' }}>{span}</span>
            </div>

        </div>
    );
};

export default EtiquetaCosto;