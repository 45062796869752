import * as yup from "yup";

const traslado = yup.object().shape({
    tipoServicio: yup.mixed().required(),
    id_evento: yup.number().required(),
    id_tipo: yup.number().required(),
    adultos: yup.number().required()
});

const reservarIda = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    fecha_ida: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            vuelo_ida: yup.string().required(),
            id_hora_ida: yup.mixed().required(),
            id_aerolinea_ida: yup.number().required()
        })
    ),
    id_metodo: yup.number().required(),
    requiere_factura: yup.bool().required()
    //comentario: yup.string().required()
});

const reservarVuelta = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            vuelo_vuelta: yup.string().required(),
            id_hora_vuelta: yup.mixed().required(),
            id_aerolinea_vuelta: yup.number().required()
        })
    ),
    id_metodo: yup.number().required(),
    requiere_factura: yup.bool().required()
    //comentario: yup.string().required()
});


const reservarRedonodo = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    fecha_ida: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            vuelo_ida: yup.string().required(),
            vuelo_vuelta: yup.string().required(),
            id_hora_ida: yup.mixed().required(),
            id_hora_vuelta: yup.mixed().required(),
            id_aerolinea_ida: yup.number().required(),
            id_aerolinea_vuelta: yup.number().required()
        })
    ),
    id_metodo: yup.number().required(),
    requiere_factura: yup.bool().required()
    //comentario: yup.string().required()
});

const reservarIdaTour = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    fecha_ida: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            id_hora_ida: yup.mixed().required(),
        })
    ),
    id_metodo: yup.number().required(),
    requiere_factura: yup.bool().required()
    //comentario: yup.string().required()
});

const reservarVueltaTour = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            id_hora_vuelta: yup.mixed().required(),
        })
    ),
    id_metodo: yup.number().required(),
    requiere_factura: yup.bool().required()
    //comentario: yup.string().required()
});


const reservarRedonodoTour = yup.object().shape({
    nombre: yup.string().required(),
    correo: yup.string().required(),
    telefono: yup.string().required(),
    fecha_ida: yup.string().required(),
    pasajeros: yup.array().of(
        yup.object().shape({
            nombre: yup.string().required(),
            id_hora_ida: yup.mixed().required(),
            id_hora_vuelta: yup.mixed().required(),
        })
    ),
    id_metodo: yup.number().required(),
    requiere_factura: yup.bool().required()
    //comentario: yup.string().required()
});

const factura = yup.object().shape({
    rfc: yup.string().required(),
    cliente: yup.string().required(),
    domicilio: yup.string().required()
});

const TrasladoValidacion = {
    traslado,
    reservarIda,
    reservarVuelta,
    reservarRedonodo,
    factura,
    reservarIdaTour,
    reservarVueltaTour,
    reservarRedonodoTour
};

export default TrasladoValidacion;