/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Message } from 'primereact/message';

const Alert = ({ tipo, text, lg, md, sm }) => {

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-1`}>
            {text &&
                <Message severity={tipo} text={text} />
            }
        </div>
    );
};

export default Alert;