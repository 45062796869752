import ServiceFactory from "./ServiceFactory";
const controller = "/apiSource";

const SourceAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        obtener: async (source) => service.get(`${controller}/${source}`)
    }
};

export default SourceAPI;
