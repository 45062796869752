import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import StripeApi from "../../API/Servicios/StripeAPI";

const CheckoutForm = ({ actualizar, loading }) => {
    const stripe = useStripe();
    const elements = useElements();
    const api = StripeApi();
    const [pagando, setPagando] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        setPagando(true);
        loading(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_API_URL}/apiStripe/success`
            },
            redirect: 'if_required'
        });

        if (result?.error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(result?.error?.message);
            loading(false);
            setPagando(false);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            const response = await api.success(result.paymentIntent.id, result.paymentIntent.client_secret, result.paymentIntent.status);
            if (parseInt(response.id) > 0) {
                actualizar();
                loading(false);
                setPagando(false);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            {!pagando &&
                <button disabled={!stripe} className="btn btn-primary mt-2">
                    <span className='pi pi-credit-card'></span> Realizar Pago
                </button>
            }
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    )
};

export default CheckoutForm;