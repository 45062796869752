/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useReducer, useState } from "react";
import AerolineaAPI from "../API/Catalogo/AerolineaAPI";
import FormReducer from "../Component/FormReducer";
import TextBox from "../Component/TextBox";
import AerolineaValidacion from "./DetalleAerolineaValidation";

const DetalleAerolinea = ({ item, cerrar, loading }) => {
    const api = AerolineaAPI();
    const defaultEntity = () => ({
        entity: {
            id_aerolinea: 0,
            nombre: '',
            activo: true
        }
    });
    const [confirmar, setConfirmar] = useState(false);
    const [mensaje, setMensaje] = useState(null);
    const [enviado, setEnviado] = useState(false);

    const [{ entity }, dispatch] = useReducer(FormReducer, {}, defaultEntity);

    useEffect(() => {
        if (item?.id_aerolinea > 0) {
            cargar();
        }
    }, []);

    const cargar = async () => {
        loading(true);
        const result = await api.detalle(item.id_aerolinea);
        dispatch({ type: 'update', value: result.item });
        loading(false);
    };

    const actualizar = (value, name) => {
        dispatch({ type: 'update', value: value, name: name });
    };

    const guardar = async () => {
        loading(true);
        setEnviado(true);
        const valido = await AerolineaValidacion.guardar.isValid(entity);
        if (valido) {
            const result = await api.guardar(entity);
            console.log(result);
            if (result.id > 0) {
                cerrar();
            }
        }
        loading(false);
    };

    const confirmarEliminar = () => {
        setMensaje("¿Eliminar el registro?");
        setConfirmar(true);
    };

    const eliminar = async () => {
        loading(true);
        const result = await api.eliminar(entity.id_aerolinea);
        if (result.id > 0) {
            cerrar();
        }
        loading(false);
    }

    return (
        <div className="row">
            <h5>Aerolinea</h5>
            <hr />
            <TextBox label={'Aerolinea'} name="nombre" value={entity?.nombre} lg={12} md={12} sm={12}
                onChange={actualizar} requerido={enviado} />
            <footer className="row py-4 mt-5">
                <div className="col-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                    <Button label="Cancelar" className="p-button-secondary p-button-outlined m-1"
                        onClick={cerrar} />
                    {entity?.id_aerolinea > 0 &&
                        <Button label="Eliminar" className="p-button-danger p-button-outlined m-1"
                            onClick={confirmarEliminar} />
                    }
                    <Button label="Guardar" className="p-button-outlined m-1"
                        onClick={guardar} disabled={enviado} />
                </div>
            </footer>
            <ConfirmDialog visible={confirmar} onHide={() => setConfirmar(false)} message={mensaje}
                header="Confirmar" icon="pi pi-exclamation-triangle" accept={eliminar} reject={() => setConfirmar(false)} />
        </div>
    );
};

export default DetalleAerolinea;