import React, { useState } from "react";
import { InputSwitch } from 'primereact/inputswitch';

const Switch = ({ name, label, value, onChange, lg, md, sm, requerido, css }) => {
    const [selected, setSelected] = useState(false);

    const change = (e) => {
        const value = e.value;
        setSelected(e.value);
        onChange(value, name);
    };

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} ${css ?? ''}`}>
            <div className="form-group d-flex flex-row">
                <InputSwitch name={name} value={value} className={requerido && ((value ?? '') === '' || value === null) ? 'p-invalid block' : ''}
                    onChange={change} checked={selected} /> <label htmlFor={name} className='aceptar-terminos p-1'>{label}</label>
            </div>
        </div>
    );
};

export default Switch;