/* eslint-disable react-hooks/exhaustive-deps */
import { Toast } from "primereact/toast";
import React, { useEffect, useReducer, useRef, useState } from "react";
import TrasladoAPI from "../API/Servicios/TrasladoAPI";
import FormReducer from "../Component/FormReducer";
import DetalleReservacion from "./DetalleReservacion";
import Reservacion from "./Reservacion";
import Traslado from "./Traslado";
import { Sidebar } from "primereact/sidebar";
import Alert from "../Component/Alert";

const initialEntity = {
    id_transportacion: 0,
    pasajeros: [],
    adultos: 0,
    menores: 0,
    id_estatus: 1,
    nombre: null,
    correo: null,
    telefono: null,
    id_factura: 0,
    rfc: '',
    cliente: '',
    domicilio: '',
    lada: 52
};

const Home = ({ loading }) => {
    const [paso, setPaso] = useState(0);
    const defaultEntity = () => ({
        entity: initialEntity
    });
    const toastBR = useRef(null);
    const [ver, setVer] = useState(false);
    const api = TrasladoAPI();
    const [{ entity }, dispatch] = useReducer(FormReducer, {}, defaultEntity);
    const [noEncontrado, setNoEncontrado] = useState('');
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');

    useEffect(() => {
        if (id !== undefined && id !== null) {
            buscar(id);
        }
    }, []);

    const actualizar = (value, name) => {
        dispatch({ type: 'update', value: value, name: name });
    };

    const mostrar = async (id) => {
        let result = await api.obtener(id);
        result.item.pasajeros = result.pasajeros;
        result.item.factura = result.factura;
        dispatch({ type: 'update', value: result.item });
        setPaso(0);
        showBottomRight();
        setVer(true);
    };

    const showBottomRight = () => {
        toastBR.current.show({ severity: 'success', summary: 'Operacion Exitosa', detail: 'Operacion realizada correctamente.', life: 3000 });
    }

    const buscar = async (folio) => {
        loading(true);
        setNoEncontrado('');
        let servicio = await api.buscar(folio);
        console.log(servicio);
        if (servicio.item === null && (id === undefined || id === null)) {
            setNoEncontrado('Folio no encontrado.');
            loading(false);
            return;
        }

        servicio.item.pasajeros = servicio.pasajeros;
        servicio.item.factura = servicio.factura;
        dispatch({ type: 'update', value: servicio.item });
        showBottomRight();
        setVer(true);
        setNoEncontrado('');
        loading(false);
    };

    const regresar = () => {
        dispatch({ type: 'update', value: initialEntity });
        setPaso(0);
    };

    const reload = async (id) => {
        loading(true);
        setVer(false);
        let result = await api.obtener(id);
        result.item.pasajeros = result.pasajeros;
        result.item.factura = result.factura;
        dispatch({ type: 'update', value: result.item });
        setPaso(0);
        setVer(true);
        loading(false);
    }

    return (
        <>
            {noEncontrado !== '' &&
                <Alert tipo="error" text={noEncontrado} lg={12} md={12} sm={12} />
            }
            {paso === 0 &&
                // <div className="container mt-2">
                //     <div className="row">
                //         <div className="col-lg-12 col-md-12 col-sm-12  container-min">
                //             <h5>Contactanos para reservaciones</h5>
                //             <a href="mailto:traslados@ctcongresosyconvenciones.com.mx" className="btn-pie m-2 txt-link"><i className="pi pi-envelope"></i> traslados@ctcongresosyconvenciones.com.mx</a> <br />
                //             <a href="https://api.whatsapp.com/send?phone=528123507070" target="_new" className="btn-pie m-2 txt-link"><i className="pi pi-whatsapp"></i> 81 2350-7070</a><br />
                //             <a href="tel:8117461577" className="btn-pie m-2 txt-link"><i className="pi pi-mobile"></i> 81 1746-1577</a><br />
                //             <a href="tel:8147802370" className="btn-pie m-2 txt-link"><i className="pi pi-phone"></i> 814780-2370</a>
                //         </div>
                //     </div>
                // </div>
                 <Traslado entity={entity} onChange={actualizar} paso={setPaso} onBuscar={buscar} />
            }
            {paso === 1 &&
                <Reservacion entity={entity} onChange={actualizar} paso={regresar} mostrar={mostrar} loading={loading} />
            }

            <Sidebar visible={ver} position="right" fullScreen className="p-sidebar-lg"
                onHide={() => setVer(false)} dismissable={false}>
                <DetalleReservacion entity={entity} change={actualizar} showMessage={showBottomRight} reload={reload} loading={loading} />
            </Sidebar>
            <Toast ref={toastBR} position="bottom-right" style={{ zIndex: 999 }} />
        </>
    );
};

export default Home;