import ServiceFactory from "../ServiceFactory";
const controller = "/apiSeguridad";

const SeguridadAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        login: async (params) => service.post(`${controller}/login`, params),
        obtener: async () => service.get(`${controller}/obtener`),
        detalle: async (id) => service.get(`${controller}/detalle/${id}`),
        guardar: async (params) => service.post(`${controller}/guardar`, params),
        eliminar: async (id) => service.delete(`${controller}/eliminar/${id}`)
    }
};

export default SeguridadAPI;