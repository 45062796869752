import ServiceFactory from "../ServiceFactory";
const controller = "/apiStripe";

const TrasladoAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        success: async (intent, secret, status) => service.post(`${controller}/success/${intent}/${secret}/${status}`, {})
    }
};

export default TrasladoAPI;
