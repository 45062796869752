import { TabPanel, TabView } from "primereact/tabview";
import React, { useState } from "react";
import Eventos from "./Evento";
import Tours from "./Tour";

const Servicios = ({ loading }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className="container container-min">
            <div className="row">
                <h5>Servicios</h5>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <TabView activeIndex={activeIndex} onChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Servicios">
                            <Eventos loading={loading} />
                        </TabPanel>
                        <TabPanel header="Tours">
                            <Tours loading={loading} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
};

export default Servicios;