/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import { Badge } from 'primereact/badge';
import * as numeral from "numeral";
import { Button } from "primereact/button";
import Combo from "../Component/Combo";
import Radio from "../Component/RadioButton";
import SourceAPI from "../API/SourceAPI";
import TrasladoValidacion from "./TrasladoValidation";
import TextBoxGroup from "../Component/TextBoxGroup";
import { ListBox } from 'primereact/listbox';

const Traslado = ({ entity, onChange, paso, onBuscar }) => {
    const source = SourceAPI();
    const [servicios, setServicios] = useState([]);
    const [traslados, setTraslados] = useState([]);
    const [numeroPasajeros, setNumeroPasajeros] = useState([]);
    const [enviado, setEnviado] = useState(false);
    const [folio, setFolio] = useState('');

    const tipo = [
        { id: 1, nombre: 'Evento' },
        { id: 2, nombre: 'Tour' }
    ];

    useEffect(() => {
        cargar();
    }, []);

    const cargar = async () => {
        const pasajeros = await source.obtener('pasajeros');
        setNumeroPasajeros(pasajeros.items);
    };

    const actualizar = async (value, name) => {
        onChange(value, name);

        if (name === 'tipoServicio') {
            const servicio = await source.obtener(`servicio/${value?.id}`);
            setServicios(servicio.items);
            onChange(value?.id, 'id_servicio');
        }

        if (name === 'id_evento') {
            const traslado = await source.obtener(`traslados/${value}`)
            console.log(traslado);
            setTraslados(traslado.items);
        }

        if (name === 'id_tipo') {
            const item = traslados.find(t => t.id_tipo_transporte === value);
            console.log(item);
            onChange(item?.id_tipo_traslado, 'id_tipo_traslado');
        }
    };

    const siguiente = async () => {
        setEnviado(true);
        var valido = await TrasladoValidacion.traslado.isValid(entity);
        if (valido) {
            let pasajeros = [];
            for (let i = 1; i <= entity.adultos; i++) {
                pasajeros.push({
                    id: i,
                    id_pasajero: 0,
                    nombre: '',
                    correo: '',
                    telefono: '',
                    vuelo_ida: '',
                    vuelo_vuelta: '',
                    esPrincipal: i === 1 ? true : false,
                    id_tipo_traslado: parseInt(entity?.id_tipo_traslado),
                    lada: 52
                });
            };

            if ((entity?.menores ?? 0) > 0) {
                const total = entity?.pasajeros.length;
                for (let j = 1; j <= entity.menores; j++) {
                    pasajeros.push({
                        id: total + j,
                        id_pasajero: 0,
                        nombre: '',
                        correo: '',
                        telefono: '',
                        vuelo_ida: '',
                        vuelo_vuelta: '',
                        esPrincipal: false,
                        id_tipo_traslado: parseInt(entity?.id_tipo_traslado),
                        lada: 52
                    });
                };
            };

            actualizar('', 'comentario');
            actualizar(pasajeros, 'pasajeros');

            paso(1);
        }
    };

    const buscar = async () => {
        onBuscar(folio);
    };

    const onKeyEnter = (e) => {
        console.log(e);
        if (e.key === 'Enter') {
            onBuscar(folio);
        }
    };

    const itemTemplate = (option) => {
        return (
            <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8">
                    {option?.nombre}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4" style={{ textAlign: 'end' }}>
                    <Badge value={numeral(option?.precio).format('$ 0,0.00')} size="large" className="morado"></Badge>
                </div>
            </div>
        );
    };

    return (
        <div className="container mt-2">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 container-min">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12"></div>
                        <TextBoxGroup icono={'pi-search'} name={'folio'} value={folio} onChange={(value) => setFolio(value)} lg={6} md={6} sm={12}
                            placeholder="Buscar Folio" onClick={buscar} keyDown={onKeyEnter} />
                    </div>
                    <div className="row">
                        <Radio name={'tipoServicio'} value={entity?.tipoServicio} opciones={tipo}
                            onChange={actualizar} requerido={enviado} />
                        <Combo name={'id_evento'} label={entity?.tipoServicio?.id === 1 ? 'Evento' : 'Tour'} value={entity?.id_evento} opciones={servicios}
                            onChange={actualizar} lg={12} md={12} sm={12} nameValue="id_evento" nameLabel="nombre" requerido={enviado} />
                        <Combo name={'id_tipo'} label="Tipo de Traslado" value={entity?.id_tipo} opciones={traslados}
                            onChange={actualizar} lg={12} md={12} sm={12} nameValue="id_tipo_transporte" nameLabel="nombre" requerido={enviado} />
                        <Combo name={'adultos'} label="Pasajeros" value={entity?.adultos} opciones={numeroPasajeros}
                            onChange={actualizar} lg={12} md={12} sm={12} nameValue="value" nameLabel="label" requerido={enviado} />
                        <Combo name={'menores'} label="Menores de 2 años" value={entity?.menores} opciones={numeroPasajeros}
                            onChange={actualizar} lg={12} md={12} sm={12} nameValue="value" nameLabel="label" requerido={enviado} />
                        <div className="form-group d-flex flex-column mt-2 etiqueta">
                            <ListBox options={traslados} itemTemplate={itemTemplate} emptyMessage="Sin información para mostrar." />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mt-2 botones" style={{ textAlign: 'end' }}>
                            <Button label="Siguiente" icon="pi pi-arrow-right" iconPos="right" className="btn-verde"
                                onClick={siguiente} title="Siguiente" type="button" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="text-light">Precio por persona, traslado en autobús de lujo.</label>
                    <label className="text-light">Niños menores de 2 años viajan gratis en las piernas de un adulto.</label>
                    <label className="text-light">Si requieres traslados privados y especiales solicita tu cotización.</label>
                </div>
            </div>
        </div>
    );
};

export default Traslado;