/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import TrasladoAPI from "../API/Servicios/TrasladoAPI";
import Calendario from "../Component/Calendario";
import FormReducer from "../Component/FormReducer";
import { Constantes } from "../Shared/Constantes";
import Etiqueta from "../Shared/Control/EtiquetaEvento";
import { TabPanel, TabView } from "primereact/tabview";
import TextBox from "../Component/TextBox";
import moment from "moment";
import Combo from "../Component/Combo";
import { Button } from "primereact/button";
import SourceAPI from "../API/SourceAPI";
import produce from "immer";
import DetalleValidacion from "./DetalleInicioValidation";
import { ConfirmDialog } from "primereact/confirmdialog";
import TextArea from "../Component/TextArea";
import { Image } from "primereact/image";
import NotificacionAPI from "../API/NotificacionAPI";
import { Dialog } from 'primereact/dialog';

const DetalleInicio = ({ item, cerrar, onSave, reload, loading }) => {
    const api = TrasladoAPI();
    const source = SourceAPI();
    const [tabIndex, setTabIndex] = useState(0);
    const defaultEntity = () => ({
        entity: {
            nombre: '',
            telefono: '',
            correo: ''
        }
    });
    const [aerolineas, setAerolineas] = useState([]);
    //const [horarioLlegada, setHorarioLlegada] = useState([]);
    //const [horarioRegreso, setHorarioRegreso] = useState([]);
    const [enviado, setEnviado] = useState(false);
    const [confirmar, setConfirmar] = useState(false);
    const [mensaje, setMensaje] = useState(null);
    const [metodos, setMetodos] = useState([]);
    const [proceso, setProceso] = useState(0);
    const notificar = NotificacionAPI();
    const [editarMsg, setEditarMsg] = useState(false);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [maxLength, setMaxLength] = useState(50);

    const [{ entity }, dispatch] = useReducer(FormReducer, {}, defaultEntity);

    useEffect(() => {
        cargar();
    }, []);

    const cargar = async () => {
        loading(true);
        let result = await api.obtener(item?.id_transportacion);
        result.item.tiene_ida = result.item?.id_tipo_traslado === Constantes.traslado.LLEGADA || result.item?.id_tipo_traslado === Constantes.traslado.REDONDO;
        result.item.tiene_vuelta = result.item?.id_tipo_traslado === Constantes.traslado.REGRESO || result.item?.id_tipo_traslado === Constantes.traslado.REDONDO;
        result.item.requierefactura = result.item.requiere_factura === '1';
        if (result.item?.tiene_ida) {
            let min_date = new Date(`${result.item.fecha_ida} ${result.item.hora_ida}`);
            min_date.setDate(min_date.getDate() - 1);
            setMinDate(min_date);
            setMaxDate(new Date(`${result.item.fecha_ida} ${result.item.hora_ida}`));
            result.item.fechaIda = new Date(`${result.item.fecha_ida} ${result.item.hora_ida}`);
        }

        const fecha = new Date().toDateString();
        result.pasajeros?.forEach((p) => {
            if (result.item?.tiene_ida) {
                p.id_hora_ida = new Date(fecha + ' ' + p.hora_ida);
            }

            if (result.item?.tiene_vuelta) {
                p.id_hora_vuelta = new Date(fecha + ' ' + p.hora_vuelta);
            }
        });

        result.item.pasajeros = result.pasajeros;
        dispatch({ type: 'update', value: result.item });
        const aerolinea = await source.obtener('aerolineas');
        setAerolineas(aerolinea.items);

        const horarioIda = await source.obtener(`horariosIda/${result.item.id_evento}`);
        horarioIda.items.map((h) => (
            h.strHora = moment(new Date(fecha + ' ' + h.hora)).format('LT')
        ));
        //setHorarioLlegada(horarioIda.items);
        const horarioVuelta = await source.obtener(`horariosVuelta/${result.item.id_evento}`);
        horarioVuelta.items.map(h => (
            h.strHora = moment(new Date(fecha + ' ' + h.hora)).format('LT')
        ));
        //setHorarioRegreso(horarioVuelta.items);
        const metodo = await source.obtener('metodos');
        setMetodos(metodo.items);
        loading(false);
    };

    const actualizar = (value, name) => {
        dispatch({ type: 'update', value: value, name: name });

        if (name === 'fechaIda') {
            dispatch({ type: 'update', value: moment(value).format("YYYY-MM-DD"), name: 'fecha_ida' });
        }
    };

    const actualizarPasajeros = (value, name, index) => {
        if (index === 0) {
            actualizar(value, name);
        }

        const newValue = produce(entity.pasajeros, (draft) => {
            draft[index][name] = value;
        });

        if (name === 'id_hora_ida') {
            //const horario = horarioLlegada.find(h => h.id_horario === value);
            actualizar(value.toLocaleTimeString(), 'hora_ida');

            if (index === 0) {
                actualizar(value.toLocaleTimeString(), 'hora_ida');
            }
        }

        if (name === 'id_hora_vuelta') {
            //const horario = horarioRegreso.find(h => h.id_horario === value);
            actualizar(value.toLocaleTimeString(), 'hora_vuelta');

            if (index === 0) {
                actualizar(value.toLocaleTimeString(), 'hora_vuelta');
            }
        }

        actualizar(newValue, 'pasajeros');
    };

    const guardar = async () => {
        loading(true);
        let valido = false;
        setEnviado(true);
        if (entity?.id_tipo_traslado === Constantes.traslado.LLEGADA) {
            if (entity?.id_servicio === '1') {
                valido = await DetalleValidacion.guardarIda.isValid(entity);
            }
            else {
                valido = await DetalleValidacion.guardarIdaTour.isValid(entity);
            }
        }

        if (entity?.id_tipo_traslado === Constantes.traslado.REGRESO) {
            if (entity?.id_servicio === '1') {
                valido = await DetalleValidacion.guardarVuelta.isValid(entity);
            }
            else {
                valido = await DetalleValidacion.guardarVueltaTour.isValid(entity);
            }
        }

        if (entity?.id_tipo_traslado === Constantes.traslado.REDONDO) {
            if (entity?.id_servicio === '1') {
                valido = await DetalleValidacion.guardarRedondo.isValid(entity);
            }
            else {
                valido = await DetalleValidacion.guardarRedondoTour.isValid(entity);
            }
        }

        if (valido) {
            const result = await api.actualizar(entity?.id_transportacion, entity);
            if (result?.id > 0) {
                cerrar();
                onSave();
                reload();
            }
        }
        loading(false);
    };

    const confirmDialog = (tipo) => {
        setProceso(tipo);
        if (tipo === 0) {
            setMensaje("¿Eliminar el registro?");
            setConfirmar(true);
        }

        if (tipo === 1) {
            setMensaje("¿Aceptar el comprobante?");
            setConfirmar(true);
        }

        if (tipo === 2) {
            setMensaje("¿Rechazar el comprobante?");
            setConfirmar(true);
        }

        if (tipo === 3 || tipo === 4) {
            setMaxLength(tipo === 3 ? 50 : 250);
            setMensaje('');
            setEditarMsg(true);
        }


    };

    const procesar = async () => {
        loading(true);
        if (proceso === 0) {
            const result = await api.eliminar(entity.id_transportacion);
            if (result.id > 0) {
                cerrar();
                onSave();
                reload();
            }
        }

        if (proceso === 1) {
            const result = await api.aceptar(entity.id_transportacion);
            if (result.id > 0) {
                actualizar(result.id_estatus, 'id_estatus');
                cargar()
                onSave();
                reload();
            }
        }

        if (proceso === 2) {
            const result = await api.rechazar(entity.id_transportacion);
            if (result.id > 0) {
                actualizar(result.id_estatus, 'id_estatus');
                cargar();
                onSave();
                reload();
            }
        }
        loading(false);
    };

    const enviar = async () => {
        setEnviado(true);
        if ((mensaje ?? '') === '')
            return;

        loading(true);
        if (proceso === 3) {
            const send = await notificar.createMessage(entity.id_transportacion, { mensaje: mensaje });
            console.log(send);
            // if (send?.result) {
            setMensaje('');
            setEditarMsg(false);
            onSave();
            // }
        }

        if (proceso === 4) {
            const send = await notificar.enviarCorreo(entity.id_transportacion, { mensaje: mensaje });
            console.log(send);
            // if (send?.result) {
            setMensaje('');
            setEditarMsg(false);
            onSave();
            // }
        }
        loading(false);
    };

    const actualizarMsg = (value, name) => {
        setMensaje(value);
    };

    const renderFooter = () => {
        return (
            <div className="botones">
                <Button label="Cancelar" icon="pi pi-times" onClick={() => setEditarMsg(false)} className="p-button-text" />
                <Button label="Enviar" icon="pi pi-check" onClick={enviar} autoFocus className="btn-azul" />
            </div>
        );
    };

    return (
        <div className="container-fluid overflow-hidden">
            <div className="col d-flex flex-column h-sm-100">
                <main className="row overflow-auto">
                    <TabView activeIndex={tabIndex} onChange={(e) => setTabIndex(e.index)}>
                        <TabPanel header="General">
                            <div className="row">
                                <Etiqueta label={'Folio'} span={entity?.folio} lg={4} md={4} sm={12} />
                                <Etiqueta label={'Estatus'} span={entity?.estatus} lg={4} md={4} sm={12} />
                                <Etiqueta label={entity?.id_servicio === '1' ? 'Evento' : 'Tour'} span={item?.evento} lg={4} md={4} sm={12} />
                                <Etiqueta label={'Pasajeros'} lg={4} md={4} sm={12}
                                    span={`${entity?.adultos} Adulto(s), ${entity?.menores} Menor(es)`} />
                                {entity?.id_servicio === '1' &&
                                    <Etiqueta label={'Hotel'} span={item?.hotel} lg={4} md={4} sm={12} />
                                }
                                <Etiqueta label={'Traslado'} span={entity?.tipo} lg={4} md={4} sm={12} />
                                {(entity?.id_tipo_traslado === Constantes.traslado.LLEGADA || entity?.id_tipo_traslado === Constantes.traslado.REDONDO) &&
                                    <Calendario name="fechaIda" label={'Fecha de Llegada'} value={entity?.fechaIda} lg={4} md={4} sm={12} onChange={actualizar}
                                        minDate={minDate} maxDate={maxDate} requerido={enviado} disabled={entity?.id_estatus === '7'} />
                                }
                                {(entity?.id_tipo_traslado === Constantes.traslado.REGRESO || entity?.id_tipo_traslado === Constantes.traslado.REDONDO) &&
                                    <Etiqueta label={'Fecha de Regreso'} span={moment(entity?.fecha_vuelta).format('DD/MM/yyyy')} lg={4} md={4} sm={12} />
                                }
                            </div>
                            <div className="row">
                                <TextBox label={'Nombre'} name={'nombre'} value={entity?.nombre} onChange={actualizar} lg={4} md={4} sm={12}
                                    type="text" requerido={enviado} disabled={entity?.id_estatus === '7'} />
                                <TextBox label={'Telefono'} name={'telefono'} value={entity?.telefono} onChange={actualizar} lg={4} md={4} sm={12}
                                    requerido={enviado} disabled={entity?.id_estatus === '7'} />
                                <TextBox label={'Correo'} name={'correo'} value={entity?.correo} onChange={actualizar} lg={4} md={4} sm={12}
                                    requerido={enviado} disabled={entity?.id_estatus === '7'} />
                                <Combo label={'Metodo de Pago'} name="id_metodo" value={entity?.id_metodo} opciones={metodos} lg={4} md={4} sm={12}
                                    onChange={actualizar} requerido={enviado} disabled={entity?.id_estatus !== '1'} />
                                <Etiqueta label={'Facturar'} span={entity?.requierefactura ? 'SI' : 'NO'} lg={4} md={4} sm={12} />
                                {entity?.requierefactura &&
                                    <>
                                        <TextBox label={'Factura'} name="factura" value={entity?.factura} lg={4} md={4} sm={12}
                                            requerido={enviado} onChange={actualizar} />
                                    </>
                                }
                                <TextArea label={'Comentarios'} name="comentario" value={entity?.comentario} rows={2}
                                    onChange={actualizar} lg={12} md={12} sm={12} requerido={enviado} />
                            </div>
                            <div className="row">
                                {entity?.requierefactura &&
                                    <>
                                        <Etiqueta label={'RFC'} span={entity?.facturar?.rfc} lg={4} md={4} sm={12} />
                                        <Etiqueta label={'Razon Social'} span={entity?.facturar?.cliente} lg={4} md={4} sm={12} />
                                        <Etiqueta label={'Domicilio'} span={entity?.facturar?.domicilio} lg={4} md={4} sm={12} />
                                    </>
                                }
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    {(entity?.id_metodo === '2' || entity?.id_metodo === '3') && entity?.id_estatus !== '1' &&
                                        <Image src={`${process.env.REACT_APP_API_URL}/assets/files/${entity?.evidencia}`} alt={entity?.folio} width="250" preview />
                                    }
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Pasajeros">
                            <div className="row">
                                {entity?.pasajeros?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <h5 className="mt-3">Pasajeros {index + 1}</h5>
                                            <hr />
                                            <TextBox label={'Nombre'} name="nombre" value={item.nombre} lg={12} md={12} sm={12} type="text" requerido={enviado}
                                                onChange={(value, name) => actualizarPasajeros(value, name, index)} disabled={entity?.id_estatus === '7'} />
                                            {(entity?.id_tipo_traslado === Constantes.traslado.LLEGADA || entity?.id_tipo_traslado === Constantes.traslado.REDONDO) &&
                                                <>
                                                    {entity?.id_servicio === '1' &&
                                                        <>
                                                            <Combo label={'Aerolinea Llegada'} name="id_aerolinea_ida" value={item?.id_aerolinea_ida} disabled={entity?.id_estatus === '7'}
                                                                opciones={aerolineas} lg={4} md={4} sm={12} requerido={enviado} onChange={(value, name) => actualizarPasajeros(value, name, index)} />
                                                            <TextBox label={'No. de Vuelo Llegada'} name="vuelo_ida" value={item?.vuelo_ida} lg={4} md={4} sm={12} type="text" requerido={enviado}
                                                                onChange={(value, name) => actualizarPasajeros(value, name, index)} disabled={entity?.id_estatus === '7'} />
                                                        </>
                                                    }
                                                    {/* <Combo label={'Hora Transporte Llegada'} name="id_hora_ida" value={item?.id_hora_ida} opciones={horarioLlegada} lg={4} md={4} sm={12}
                                                        disabled={entity?.id_estatus === '7'}
                                                        onChange={(value, name) => actualizarPasajeros(value, name, index)} nameValue="id_horario" nameLabel="strHora" requerido={enviado} /> */}
                                                    <Calendario label={'Hora Transporte Llegada'} name="id_hora_ida" requerido={enviado} esHora={true} value={item.id_hora_ida}
                                                        onChange={(value, name) => actualizarPasajeros(value, name, index)} lg={4} md={4} sm={12} />
                                                </>
                                            }
                                            {(entity?.id_tipo_traslado === Constantes.traslado.REGRESO || entity?.id_tipo_traslado === Constantes.traslado.REDONDO) &&
                                                <>
                                                    {entity?.id_servicio === '1' &&
                                                        <>
                                                            <Combo label={'Aerolinea Regreso'} name="id_aerolinea_vuelta" value={item?.id_aerolinea_vuelta} disabled={entity?.id_estatus === '7'}
                                                                opciones={aerolineas} lg={4} md={4} sm={12} requerido={enviado} onChange={(value, name) => actualizarPasajeros(value, name, index)} />
                                                            <TextBox label={'No. de Vuelo Regreso'} name="vuelo_vuelta" value={item?.vuelo_vuelta} lg={4} md={4} sm={12} type="text" requerido={enviado}
                                                                onChange={(value, name) => actualizarPasajeros(value, name, index)} disabled={entity?.id_estatus === '7'} />
                                                        </>
                                                    }
                                                    {/* <Combo label={'Hora Transporte Regreso'} name="id_hora_vuelta" value={item?.id_hora_vuelta} opciones={horarioRegreso} lg={4} md={4} sm={12}
                                                        disabled={entity?.id_estatus === '7'}
                                                        onChange={(value, name) => actualizarPasajeros(value, name, index)} nameValue="id_horario" nameLabel="strHora" requerido={enviado} /> */}
                                                    <Calendario label={'Hora Transporte Regreso'} name="id_hora_vuelta" requerido={enviado} esHora={true} value={item.id_hora_vuelta}
                                                        onChange={(value, name) => actualizarPasajeros(value, name, index)} lg={4} md={4} sm={12} />
                                                </>
                                            }
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </TabPanel>
                    </TabView>
                </main>
                <footer className="row py-4 mt-auto">
                    <div className="col-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                        <Button label="Cancelar" className="p-button-secondary p-button-outlined m-1" onClick={cerrar} />
                        {entity?.id_estatus !== '7' &&
                            <>
                                <Button label="Enviar Mensaje" className="p-button-success p-button-outlined m-1"
                                    icon="pi pi-whatsapp" iconPos="left" onClick={() => confirmDialog(3)} />
                                <Button label="Enviar Correo" className="p-button-info p-button-outlined m-1"
                                    icon="pi pi-send" iconPos="left" onClick={() => confirmDialog(4)} />
                            </>
                        }
                        {entity?.id_estatus === '1' &&
                            <Button label="Eliminar" className="p-button-danger p-button-outlined m-1"
                                onClick={() => confirmDialog(0)} />
                        }
                        {entity?.id_estatus === '2' &&
                            <>
                                <Button label="Aceptar Comporbante" className="p-button-success p-button-outlined m-1"
                                    onClick={() => confirmDialog(1)} />
                                <Button label="Rechazar Comprobante" className="p-button-warning p-button-outlined m-1"
                                    onClick={() => confirmDialog(2)} />
                            </>
                        }
                        <Button label="Guardar" className="p-button-outlined m-1" onClick={guardar} />
                    </div>
                </footer>
            </div>
            <ConfirmDialog visible={confirmar} onHide={() => setConfirmar(false)} message={mensaje} style={{ width: '50vw' }}
                header="Confirmar" icon="pi pi-exclamation-triangle" accept={procesar} reject={() => setConfirmar(false)} />
            <Dialog header="Editar mensaje" visible={editarMsg} style={{ width: '75vw' }}
                onHide={() => setEditarMsg(false)} footer={renderFooter}>
                <div className="row">
                    <TextArea label={'Mensaje'} value={mensaje} name="mensaje" lg={12} md={12} sm={12}
                        onChange={actualizarMsg} requerido={enviado} maxLength={maxLength} />
                </div>
            </Dialog>
        </div>
    );
};

export default DetalleInicio;