
const Etiqueta = ({ label, span, lg, md, sm }) => {
    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-column">
                <label className="etiquetas">{label}
                </label><span>{span}</span>
            </div>

        </div>
    );
};

export default Etiqueta;