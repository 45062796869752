import React from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";

const TextBoxGroup = ({ name, label, value, onChange, lg, md, sm, type, requerido, placeholder, onClick, icono, keyDown }) => {
    const change = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        onChange(value, name);
    };

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="p-inputgroup botones">
                <InputText type={type} id={name} name={name} value={value} placeholder={placeholder} onKeyDown={keyDown}
                    onChange={change} className={requerido && ((value ?? '') === '' || value === null) ? 'p-invalid block' : ''} />
                <Button icon={`pi ${icono}`} onClick={onClick} className="btn-azul" />
            </div>
        </div>
    );
};

export default TextBoxGroup;