/* eslint-disable react-hooks/exhaustive-deps */
import produce from "immer";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import TrasladoAPI from "../API/Servicios/TrasladoAPI";
import Calendario from "../Component/Calendario";
import EtiquetaEvento from "../Shared/Control/EtiquetaEvento";
import TextBox from "../Component/TextBox";
import Combo from "../Component/Combo";
import SourceAPI from "../API/SourceAPI";
import { Constantes } from "../Shared/Constantes";
import TextArea from "../Component/TextArea";
import Switch from "../Component/Switch";
import moment from "moment/moment";
import TrasladoValidacion from "./TrasladoValidation";
import Alert from "../Component/Alert";

const Reservacion = ({ entity, onChange, paso, mostrar, loading }) => {
    const api = TrasladoAPI();
    const source = SourceAPI();
    const [evento, setEvento] = useState(null);
    const [traslado, setTraslado] = useState(null);
    const [aerolineas, setAerolineas] = useState([]);
    const [enviado, setEnviado] = useState(false);
    const [metodos, setMetodos] = useState([]);
    //const [horarioLlegada, setHorarioLlegada] = useState([]);
    //const [horarioRegreso, setHorarioRegreso] = useState([]);
    const [mensaje, setMensaje] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);

    const facturar = [
        { value: true, label: 'SI' },
        { value: false, label: 'NO' }
    ];
    const ladas = [
        { value: 52, label: 'MEX +52' },
        { value: 1, label: 'USA +1' }
    ]

    useEffect(() => {
        if (entity?.id_evento > 0) {
            cargar();
        }
    }, []);

    const cargar = async () => {
        loading(true);
        let servicio = await api.servicio(entity.id_evento);
        servicio.item.fecha_ida = new Date(`${servicio.item.anio}-${servicio.item.mes_inicio}-${servicio.item.dia_inicio}`);
        servicio.item.fecha_vuelta = new Date(`${servicio.item.anio}-${servicio.item.mes_fin}-${servicio.item.dia_fin}`);
        let min_date = new Date(`${servicio.item.anio}-${servicio.item.mes_inicio}-${servicio.item.dia_inicio}`);
        min_date.setDate(min_date.getDate() - 1);
        setMinDate(min_date);
        setMaxDate(new Date(`${servicio.item.anio}-${servicio.item.mes_inicio}-${servicio.item.dia_inicio} 06:45:44`));
        onChange(moment(servicio.item.fecha_ida).format("YYYY-MM-DD"), 'fecha_ida');
        onChange(moment(servicio.item.fecha_vuelta).format("YYYY-MM-DD"), 'fecha_vuelta');
        setEvento(servicio.item);
        const transportacion = await api.transportacion(entity?.id_tipo);
        onChange(transportacion.item.id_tipo_traslado, 'id_tipo_traslado');
        setTraslado(transportacion.item);
        onChange(parseInt(transportacion.item.precio), 'precio');
        onChange(parseInt(transportacion.item.precio) * parseInt(entity?.adultos), 'importe');
        const aerolinea = await source.obtener('aerolineas');
        setAerolineas(aerolinea.items);
        const metodo = await source.obtener('metodos');
        setMetodos(metodo.items);
        //const fecha = new Date().toDateString();
        // const horarioIda = await source.obtener(`horariosIda/${entity.id_evento}`);
        // horarioIda.items.map((h) => (
        //     h.strHora = moment(new Date(fecha + ' ' + h.hora)).format('LT')
        // ));

        //setHorarioLlegada(horarioIda.items);
        // const horarioVuelta = await source.obtener(`horariosVuelta/${entity.id_evento}`);
        // horarioVuelta.items.map(h => (
        //     h.strHora = moment(new Date(fecha + ' ' + h.hora)).format('LT')
        // ));

        //setHorarioRegreso(horarioVuelta.items);
        loading(false);
    };

    const actualizar = (value, name) => {
        if (name === 'fecha_ida') {
            onChange(moment(value).format("YYYY-MM-DD"), name);
        }
        else {
            onChange(value, name);
        }

        if (name === 'fecha_ida') {
            const newValue = produce(evento, (draft) => {
                draft[name] = value;
            });
            setEvento(newValue);
        }

        if (name === 'requiere_factura') {
            if (value) {
                const iva = entity?.importe * 0.16;
                const total = entity?.importe + iva;
                onChange(iva, 'iva');
                onChange(total, 'total');
            }
            else {
                onChange(0, 'iva');
                onChange(entity?.importe, 'total');
            }
        }

        if (name === 'copiar' && value) {
            copiar();
        }
    };

    const actualizarPasajeros = (value, name, index) => {
        const newValue = produce(entity.pasajeros, (draft) => {
            draft[index][name] = value;

            if (name === 'id_hora_ida') {
                //const horario = horarioLlegada.find(h => h.id_horario === value);
                draft[index]['hora_ida'] = value.toLocaleTimeString(); //horario?.hora;

                if (index === 0) {
                    actualizar(value.toLocaleTimeString(), 'hora_ida');
                }
            }

            if (name === 'id_hora_vuelta') {
                //const horario = horarioRegreso.find(h => h.id_horario === value);
                draft[index]['hora_vuelta'] = value.toLocaleTimeString(); //horario?.hora;

                if (index === 0) {
                    actualizar(value.toLocaleTimeString(), 'hora_vuelta');
                }
            }
        });

        if (index === 0) {
            actualizar(value, name);
        }
        else {
            actualizar(true, 'puedeCopiar');
        }

        onChange(newValue, 'pasajeros');
    };

    const guardar = async () => {
        loading(true);
        setEnviado(true);
        let valido = false;

        if (traslado?.id_tipo_traslado === Constantes.traslado.LLEGADA) {
            if (entity?.id_servicio === 1) {
                valido = await TrasladoValidacion.reservarIda.isValid(entity);
            }
            else {
                valido = await TrasladoValidacion.reservarIdaTour.isValid(entity);
            }
        }

        if (traslado?.id_tipo_traslado === Constantes.traslado.REGRESO) {
            if (entity?.id_servicio === 1) {
                valido = await TrasladoValidacion.reservarVuelta.isValid(entity);
            }
            else {
                valido = await TrasladoValidacion.reservarVueltaTour.isValid(entity);
            }
        }

        if (traslado?.id_tipo_traslado === Constantes.traslado.REDONDO) {
            if (entity?.id_servicio === 1) {
                valido = await TrasladoValidacion.reservarRedonodo.isValid(entity);
            }
            else {
                valido = await TrasladoValidacion.reservarRedonodoTour.isValid(entity);
            }
        }

        if (valido) {
            if (entity?.requiere_factura) {
                valido = await TrasladoValidacion.factura.isValid(entity);
            }

            if (valido) {
                const result = await api.guardar(entity);
                if (result?.error !== '') {
                    setMensaje(result?.error);
                }
                else {
                    if (result.id > 0) {
                        paso(0);
                        mostrar(result.id);
                    }
                }
            }
        }
        loading(false);
    };

    const copiar = () => {
        const newValue = produce(entity?.pasajeros, (draft) => {
            draft?.forEach((item, index) => {
                if (index > 0) {
                    if (entity?.id_tipo_traslado === Constantes.traslado.LLEGADA || entity?.id_tipo_traslado === Constantes.traslado.REDONDO) {
                        item.vuelo_ida = draft[0]['vuelo_ida'];
                        item.id_aerolinea_ida = draft[0]['id_aerolinea_ida'];
                        item.id_hora_ida = draft[0]['id_hora_ida'];
                        //const horario = horarioLlegada.find(h => h.id_horario === draft[0]['id_hora_ida']);
                        item.hora_ida = draft[0]['id_hora_ida'].toLocaleTimeString();
                    }

                    if (entity?.id_tipo_traslado === Constantes.traslado.REGRESO || entity?.id_tipo_traslado === Constantes.traslado.REDONDO) {
                        item.vuelo_vuelta = draft[0]['vuelo_vuelta'];
                        item.id_aerolinea_vuelta = draft[0]['id_aerolinea_vuelta'];
                        item.id_hora_vuelta = draft[0]['id_hora_vuelta'];
                        //const horario = horarioRegreso.find(h => h.id_horario === draft[0]['id_hora_vuelta']);
                        item.hora_vuelta = draft[0]['id_hora_vuelta'].toLocaleTimeString();
                    }
                }
            })
        });

        onChange(newValue, 'pasajeros');
    };

    return (
        <div className="container mt-2">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 container-min">
                    <div className="form">
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ textAlign: 'end' }}>
                            <Button label="Regresar" onClick={paso} icon="pi pi-arrow-left" iconPos="left" className="p-button-info p-button-text" />
                        </div>
                    </div>
                    <div className="card mt-2 p-2">
                        <div className="row">
                            <EtiquetaEvento label={evento?.id_tipo_evento === 1 ? 'Evento' : 'Tour'} span={evento?.nombre} sm={12} md={6} lg={6} />
                            <EtiquetaEvento label={'Pasajeros:'} span={`${entity?.adultos} Adultos, ${entity?.menores ?? 0} Menores`} sm={12} md={6} lg={6} />
                            {evento?.id_tipo_evento === 1 &&
                                <EtiquetaEvento label={'Hotel:'} span={entity?.hotel} sm={12} md={6} lg={6} />
                            }
                            {/* <Calendario label={'Fecha Inicio'} name="fecha_ida" value={evento?.fecha_ida} minDate={minDate} maxDate={maxDate}
                                onChange={actualizar} sm={12} md={6} lg={6} requerido={enviado} /> */}
                            <EtiquetaEvento label={'Fecha Inicio'} span={moment(evento?.fecha_ida).format("DD/MM/YYYY")} sm={12} md={6} lg={6} />
                            <EtiquetaEvento label={'Traslado'} span={traslado?.nombre} lg={6} md={6} sm={12} />
                            <EtiquetaEvento label={'Fecha Fin'} span={`${evento?.dia_fin}/${evento?.nombre_mes_fin}/${evento?.anio}`} lg={6} md={6} sm={12} />
                        </div>
                    </div>
                    <div className="card mt-2 p-2">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-sm-12">
                                <h5><i className="pi pi-users" ></i> Pasajeros</h5>
                            </div>
                            {entity?.puedeCopiar &&
                                <Switch label={'Replicar datos del pasajero principal'} name="copiar" value={entity?.copiar}
                                    onChange={actualizar} lg={10} md={8} sm={12} />
                            }
                        </div>
                    </div>
                    {entity?.pasajeros?.map((item, index) => {
                        return (
                            <div className="row" key={index}>
                                <TextBox label={`Nombre Pasajero ${index + 1}`} name="nombre" value={item?.nombre} lg={4} md={4} sm={12} type="text"
                                    onChange={(value, name) => actualizarPasajeros(value, name, index)} requerido={enviado} />
                                {index === 0 &&
                                    <>
                                        <TextBox label={'Correo'} name="correo" value={item?.correo} lg={4} md={4} sm={12} type="email"
                                            onChange={(value, name) => actualizarPasajeros(value, name, index)} requerido={enviado} />
                                        <Combo label={'Lada'} name="lada" value={item?.lada} opciones={ladas} lg={2} md={2} sm={12}
                                            onChange={(value, name) => actualizarPasajeros(value, name, index)} nameValue="value" nameLabel="label" requerido={enviado} />
                                        <TextBox label={'No. de Telefono'} name="telefono" value={item?.telefono} lg={2} md={2} sm={12} type="tel"
                                            onChange={(value, name) => actualizarPasajeros(value, name, index)} requerido={enviado} maxLength={10} />
                                    </>
                                }
                                {(traslado?.id_tipo_traslado === Constantes.traslado.LLEGADA || traslado?.id_tipo_traslado === Constantes.traslado.REDONDO) &&
                                    <>
                                        {index === 0 &&
                                            <EtiquetaEvento label={entity?.id_servicio === 1 ? 'Llegada' : 'Salida'}
                                                span={entity?.id_servicio === 1 ? 'Aeropuerto - Hotel' : 'Hotel - Destino'} lg={4} md={4} sm={6} />
                                        }
                                        {entity?.id_servicio === 1 &&
                                            <>
                                                <Combo label={index === 0 ? 'Aerolinea' : 'Aerolinea Llegada'} name="id_aerolinea_ida" value={item?.id_aerolinea_ida} opciones={aerolineas} lg={4} md={4} sm={12}
                                                    onChange={(value, name) => actualizarPasajeros(value, name, index)} nameValue="value" nameLabel="label" requerido={enviado} />
                                                <TextBox label={index === 0 ? 'No. de Vuelo' : 'No. de Vuelo Llegada'} name="vuelo_ida" value={item?.vuelo_ida} lg={2} md={2} sm={12} type="text"
                                                    onChange={(value, name) => actualizarPasajeros(value, name, index)} requerido={enviado} />
                                            </>
                                        }
                                        {/* <Combo label={'Hora Transporte Llegada'} name="id_hora_ida" value={item?.id_hora_ida} opciones={horarioLlegada} lg={2} md={2} sm={12}
                                            onChange={(value, name) => actualizarPasajeros(value, name, index)} nameValue="id_horario" nameLabel="strHora" requerido={enviado} /> */}
                                        <Calendario label={'Hora Llegada'} name="id_hora_ida" requerido={enviado} esHora={true} value={item.id_hora_ida}
                                            onChange={(value, name) => actualizarPasajeros(value, name, index)} lg={2} md={2} sm={12} />
                                    </>
                                }

                                {(traslado?.id_tipo_traslado === Constantes.traslado.REGRESO || traslado?.id_tipo_traslado === Constantes.traslado.REDONDO) &&
                                    <>
                                        {index === 0 ?
                                            <EtiquetaEvento label={'Regreso'}
                                                span={entity?.id_servicio === 1 ? 'Hotel - Aeropuerto' : 'Destino - Hotel'} lg={4} md={4} sm={6} />
                                            :
                                            <div className={'col-lg-4 col-md-4 col-sm-12 mt-2'}></div>
                                        }
                                        {entity?.id_servicio === 1 &&
                                            <>
                                                <Combo label={index === 0 ? 'Aerolinea' : 'Aerolinea Regreso'} name="id_aerolinea_vuelta" value={item?.id_aerolinea_vuelta} opciones={aerolineas} lg={4} md={4} sm={12}
                                                    onChange={(value, name) => actualizarPasajeros(value, name, index)} nameValue="value" nameLabel="label" requerido={enviado} />
                                                <TextBox label={index === 0 ? 'No. de Vuelo' : 'No. de Vuelo Regreso'} name="vuelo_vuelta" value={item?.vuelo_vuelta} lg={2} md={2} sm={12} type="text"
                                                    onChange={(value, name) => actualizarPasajeros(value, name, index)} requerido={enviado} />
                                            </>
                                        }
                                        {/* <Combo label={'Hora Transporte Regreso'} name="id_hora_vuelta" value={item?.id_hora_vuelta} opciones={horarioRegreso} lg={2} md={2} sm={12}
                                            onChange={(value, name) => actualizarPasajeros(value, name, index)} nameValue="id_horario" nameLabel="strHora" requerido={enviado} /> */}
                                        <Calendario label={'Hora Regreso'} name="id_hora_vuelta" requerido={enviado} esHora={true} value={item.id_hora_vuelta}
                                            onChange={(value, name) => actualizarPasajeros(value, name, index)} lg={2} md={2} sm={12} />
                                    </>
                                }
                            </div>
                        );
                    })}
                    <div className="row">
                        <Combo label={'Metodo de Pago'} name="id_metodo" value={entity?.id_metodo} opciones={metodos} lg={4} md={4} sm={12}
                            onChange={actualizar} requerido={enviado} />
                        <Combo label={'¿Facturar?'} name="requiere_factura" value={entity?.requiere_factura} opciones={facturar} lg={4} md={4} sm={12}
                            onChange={actualizar} requerido={enviado} />
                        {entity?.requiere_factura &&
                            <>
                                <TextBox label={'R.F.C.'} name="rfc" value={entity?.rfc} lg={4} md={4} sm={12} type="text"
                                    onChange={actualizar} requerido={enviado} />
                                <TextBox label={'Razon Social'} name="cliente" value={entity?.cliente} lg={4} md={4} sm={12} type="text"
                                    onChange={actualizar} requerido={enviado} />
                                <TextBox label={'Domicilo Fiscal'} name="domicilio" value={entity?.domicilio} lg={8} md={8} sm={12} type="text"
                                    onChange={actualizar} requerido={enviado} />
                            </>
                        }
                        <TextArea label={'Comentarios'} name="comentario" value={entity?.comentario} rows={2}
                            onChange={actualizar} lg={12} md={12} sm={12} />
                    </div>
                    <div className="card mt-2 p-2">
                        <div className="row">
                            <Switch label={'Acepto terminos y condiciones'} name="acepto" value={entity?.acepto}
                                onChange={actualizar} lg={12} md={12} sm={12} css="mt-2" />
                            <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <label className="terminos">
                                    La transportación NO es reembolsable. Le informamos que los datos personales que usted ha proporcionado son confidenciales y serán tratados de conformidad con los establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {entity?.acepto &&
                            <>
                                <Alert tipo="warn" text={mensaje} lg={6} md={6} sm={12} />
                                <div className="col-lg-6 col-md-6 col-sm-12 botones" style={{ textAlign: 'end' }}>
                                    <div className="m-1">
                                        {entity?.id_metodo === Constantes.metodo.Tarjeta ?
                                            <Button label="Pagar" onClick={guardar} icon="pi pi-credit-card" iconPos="left" className="btn-verde" />
                                            :
                                            <Button label="Reservar" onClick={guardar} icon="pi pi-money-bill" iconPos="left" className="btn-verde" />
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reservacion;