/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import EtiquetaCosto from "../Shared/Control/EtiquetaCosto";
import Etiqueta from "../Shared/Control/EtiquetaEvento";
import numeral from "numeral";
import { Constantes } from "../Shared/Constantes";
import moment from "moment";
import Alert from "../Component/Alert";
import TrasladoAPI from "../API/Servicios/TrasladoAPI";
import { Image } from 'primereact/image';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Shared/Control/CheckOutForm";
import { loadStripe } from "@stripe/stripe-js";

const DetalleReservacion = ({ entity, change, showMessage, reload, loading }) => {
    const ida = Constantes.traslado.LLEGADA;
    const vuelta = Constantes.traslado.REGRESO;
    const redondo = Constantes.traslado.REDONDO;
    const [clientSecret, setClientSecret] = useState('');
    const api = TrasladoAPI();
    const [promise,] = useState(() => loadStripe(`${process.env.REACT_APP_PUBLISHIABLE_KEY}`));

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        if (entity?.id_metodo === Constantes.metodo.Tarjeta && clientSecret === '' && entity?.id_estatus !== '3') {
            window
                .fetch(`${process.env.REACT_APP_API_URL}/apiStripe/insertOrder`, {
                    method: "POST",
                    body: JSON.stringify({ items: [{ id: "xl-tshirt", total: entity.total, id_transportacion: entity.id_transportacion, nombre: entity.nombre, correo: entity.correo }] })
                })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    console.log(data);
                    setClientSecret(data.clientSecret);
                });
        }

    }, [promise]);

    const upload = async (e) => {
        loading(true);
        let formData = new FormData();
        formData.append("comprobante", e.target.files[0]);
        const result = await api.cargar(entity?.id_transportacion, formData);
        if (result?.id > 0) {
            change(result?.file, 'evidencia');
            change(result?.id_estatus, 'id_estatus');
            showMessage();
        }
        loading(false);
    };

    const refresh = () => {
        showMessage();
        reload(entity?.id_transportacion);
    }

    return (
        <div className="row">
            <Alert tipo={entity?.id_estatus === '3' ? 'success' : 'info'}
                text={entity?.id_estatus === '3' ? '¡Gracias por su pago! En breve se le informara la autorización de su pago, finalizando el proceso exitosamente.' : '¡Gracias por su reservacion!, continue con el proceso de pago.'} />
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card mt-2 p-2">
                    <h5><i className="pi pi-list"></i> Informacion General</h5>
                    <hr />
                    <div className="row">
                        <Etiqueta label={'Folio'} span={entity?.folio} sm={12} md={4} lg={4} />
                        <Etiqueta label={'Estatus'} span={entity?.estatus} sm={12} md={4} lg={4} />
                        <Etiqueta label={entity?.id_servicio === '1' ? 'Evento' : 'Tour'} span={entity?.evento} sm={12} md={4} lg={4} />
                        <Etiqueta label={'Participantes:'} span={`${entity?.adultos} Adultos, ${entity?.menores ?? 0} Menores`} sm={12} md={4} lg={4} />
                        {entity?.id_tipo_evento === '1' &&
                            <Etiqueta label={'Hotel:'} span={entity?.hotel} sm={12} md={4} lg={4} />
                        }
                        <Etiqueta label={'Traslado'} span={entity?.tipo} lg={4} md={4} sm={12} />
                        {(entity?.id_tipo_traslado === ida || entity?.id_tipo_traslado === redondo) &&
                            <Etiqueta label={'Fecha Inicio'} span={moment(entity?.fecha_ida).format("DD/MM/YYYY")} lg={4} md={4} sm={12} />
                        }
                        {(entity?.id_tipo_traslado === vuelta || entity?.id_tipo_traslado === redondo) &&
                            <Etiqueta label={'Fecha Fin'} span={moment(entity?.fecha_vuelta).format("DD/MM/YYYY")} lg={4} md={4} sm={12} />
                        }
                    </div>
                    <div className="row">
                        <Etiqueta label={'Nombre'} span={entity?.nombre} lg={4} md={4} sm={12} />
                        <Etiqueta label={'Telefono'} span={entity?.telefono} lg={4} md={4} sm={12} />
                        <Etiqueta label={'Correo'} span={entity?.correo} lg={4} md={4} sm={12} />
                        <Etiqueta label={'Metodo de Pago'} span={entity?.metodo_pago} lg={4} md={4} sm={12} />
                        {entity?.id_metodo === '1' && entity?.id_estatus === '3' &&
                            <Etiqueta label={'Referencia de pago'} span={entity?.referencia} lg={4} md={4} sm={12} />
                        }
                        <Etiqueta label={'Factura'} span={entity?.requiere_factura === '1' ? 'SI' : 'NO'} lg={4} md={4} sm={12} />
                    </div>
                    {entity?.requiere_factura === '1' &&
                        <div className="row">
                            <Etiqueta label={'R.F.C.'} span={entity?.factura?.rfc} lg={4} md={4} sm={12} />
                            <Etiqueta label={'Razon Social'} span={entity?.factura?.cliente} lg={4} md={4} sm={12} />
                            <Etiqueta label={'Direccion Fiscal'} span={entity?.factura?.domicilio} lg={4} md={4} sm={12} />
                        </div>
                    }
                </div>
                <div className="card mt-2 p-2">
                    <h5><i className="pi pi-dollar"></i> Costo</h5>
                    <hr />
                    <div className="row">
                        <EtiquetaCosto label={'Precio'} span={numeral(entity?.precio).format('$ 0,0.00')} lg={12} md={12} sm={12} />
                        <EtiquetaCosto label={'Importe'} span={numeral(entity?.importe).format('$ 0,0.00')} lg={12} md={12} sm={12} />
                        <EtiquetaCosto label={'I.V.A.'} span={numeral(entity?.iva).format('$ 0,0.00')} lg={12} md={12} sm={12} />
                        <EtiquetaCosto label={'Total'} span={numeral(entity?.total).format('$ 0,0.00')} lg={12} md={12} sm={12} />
                    </div>
                </div>
                <div className="card mt-2 p-2">
                    <h5><i className="pi pi-users"></i> Pasajeros</h5>
                    <hr />
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead className="etiquetas" style={{ textAlign: 'center' }}>
                                        <tr>
                                            <th></th>
                                            {(entity?.id_tipo_traslado === ida || entity?.id_tipo_traslado === redondo) &&
                                                <th colSpan={entity?.id_servicio === '1' ? 3 : 1}>{entity?.id_servicio === '1' ? 'Aeropuerto - Hotel' : 'Hotel - Destino'}</th>
                                            }

                                            {(entity?.id_tipo_traslado === vuelta || entity?.id_tipo_traslado === redondo) &&
                                                <th colSpan={entity?.id_servicio === '1' ? 3 : 1}>{entity?.id_servicio === '1' ? 'Hotel - Aeropuerto' : 'Destino - Hotel'}</th>
                                            }
                                        </tr>
                                        <tr>
                                            <th>Nombre</th>
                                            {(entity?.id_tipo_traslado === ida || entity?.id_tipo_traslado === redondo) &&
                                                <>
                                                    {entity?.id_servicio === '1' &&
                                                        <>
                                                            <th>Aerolinea</th>
                                                            <th>No. de Vuelo</th>
                                                        </>
                                                    }
                                                    <th>Hora</th>
                                                </>
                                            }

                                            {(entity?.id_tipo_traslado === vuelta || entity?.id_tipo_traslado === redondo) &&
                                                <>
                                                    {entity?.id_servicio === '1' &&
                                                        <>
                                                            <th>Aerolinea</th>
                                                            <th>No. de Vuelo</th>
                                                        </>
                                                    }
                                                    <th>Hora</th>
                                                </>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entity?.pasajeros?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.nombre}</td>
                                                    {(entity?.id_tipo_traslado === ida || entity?.id_tipo_traslado === redondo) &&
                                                        <>
                                                            {entity?.id_servicio === '1' &&
                                                                <>
                                                                    <td style={{ textAlign: 'center' }}>{item?.aerolinea_ida}</td>
                                                                    <td style={{ textAlign: 'center' }}>{item?.vuelo_ida}</td>
                                                                </>
                                                            }
                                                            <td style={{ textAlign: 'center' }}>{moment(new Date(entity?.fecha_ida + ' ' + item?.hora_ida)).format("LT")}</td>
                                                        </>
                                                    }

                                                    {(entity?.id_tipo_traslado === vuelta || entity?.id_tipo_traslado === redondo) &&
                                                        <>
                                                            {entity?.id_servicio === '1' &&
                                                                <>
                                                                    <td style={{ textAlign: 'center' }}>{item?.aerolinea_vuelta}</td>
                                                                    <td style={{ textAlign: 'center' }}>{item?.vuelo_vuelta}</td>
                                                                </>
                                                            }
                                                            <td style={{ textAlign: 'center' }}>{moment(new Date(entity?.fecha_vuelta + ' ' + item?.hora_vuelta)).format("LT")}</td>
                                                        </>
                                                    }
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-2 p-2">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            {entity?.id_metodo !== Constantes.metodo.Tarjeta &&
                                <>
                                    {(entity?.id_estatus === Constantes.estatus.PorPagar || entity?.id_estatus === Constantes.estatus.ComprobanteRechazado) &&
                                        <>
                                            <label>Adjunta tu comprobante de pago para que se procese tu reservacion</label>
                                            <input type="file" accept="image/*" name="comprobante" id="comprobante" className="form-control"
                                                placeholder="Comprobante de pago" required onChange={upload} />
                                        </>
                                    }
                                    {entity?.evidencia &&
                                        <Image src={`${process.env.REACT_APP_API_URL}/assets/files/${entity?.evidencia}`} alt={entity?.evidencia} width="150" height="150" preview />
                                    }
                                </>
                            }
                            {entity?.id_estatus === '3' &&
                                <Image src={`${process.env.REACT_APP_API_URL}/assets/qr/${entity?.folio}.png`} alt={entity?.folio} width="250" preview />
                            }
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12" style={{ textAlign: 'end' }}>
                            {entity?.id_metodo === Constantes.metodo.Tarjeta && clientSecret !== '' && entity?.id_estatus !== '3' &&
                                <Elements stripe={promise} options={{ clientSecret: clientSecret }}>
                                    <CheckoutForm actualizar={refresh} loading={loading} />
                                </Elements>
                            }
                        </div>
                    </div>
                </div>
                {entity?.id_metodo !== Constantes.metodo.Tarjeta &&
                    <div className="card mt-2 p-2">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12" style={{ textAlign: 'center' }}>
                                {entity?.id_metodo === Constantes.metodo.Transferencia &&
                                    <>
                                        <img src={`${process.env.REACT_APP_API_URL}/assets/images/Banco_Santander_Logotipo.png`} alt="Banco Santander" />
                                        <h3 className="text-primary">Clabe Interbancaria: <span className="text-bold text-black">014580605332428083</span ></h3>
                                    </>
                                }
                                {entity?.id_metodo === Constantes.metodo.Deposito &&
                                    <>
                                        <img src={`${process.env.REACT_APP_API_URL}/assets/images/Banco_Santander_Logotipo.png`} alt="Banco Santander" />
                                        <h3 className="text-primary">Número de cuentra: <span className="text-bold text-black">60-53324280-8</span ></h3>
                                        <img src={`${process.env.REACT_APP_API_URL}/assets/images/OXXO_logo.png`} alt="Banco Santander" />
                                        <h3 className="text-primary">Tarjeta de pago OXXO: <span className="text-bold text-black">5579 0701 1662 6980</span ></h3>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default DetalleReservacion;