import React from "react";
import { Menubar } from "primereact/menubar";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

const Header = ({ entrar, eslogin }) => {
    const items = [
        {
            label: 'Traslados',
            template: (item, options) => {
                return (
                    <>
                        {eslogin ?
                            <Link className={options.className} onClick={() => entrar(false)}>
                                <span><i className='pi pi-sign-out' /> {item.label}</span>
                            </Link>
                            :
                            <Link to="/" target={item.target} className={options.className}>
                                <span><i className="pi pi-truck" /> {item.label}</span>
                            </Link>
                        }
                    </>
                );
            }
        }
    ];

    const intro = () => {
        entrar(true);
    }

    return (
        <header className="encabezado">
            <div className="form-row d-flex d-flex-column">
                <div className="col-lg-4 col-md-4 col-sm-12" style={{ padding: '10px' }}>
                    <img src={require('../images/backgroud_transparente_blanco.png')} className="logo-transparente-blanco" alt="CT Congresos & Convenciones" />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 botones">
                    {!eslogin &&
                        <div className="m-1" style={{ textAlign: 'end' }}>
                            <Button icon="pi pi-sign-in" className="btn-text-morado"
                                onClick={intro} />
                        </div>
                    }
                    <h1 className="text-center m-2">Bienvenido</h1>
                </div>
            </div>
            <Menubar model={items} className="mnu-azul" />
        </header>
    );
};

export default Header;