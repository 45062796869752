/* eslint-disable react-hooks/exhaustive-deps */
import produce from "immer";
import { Button } from "primereact/button";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { TabPanel, TabView } from "primereact/tabview";
import React, { Fragment, useEffect, useReducer, useState } from "react";
import EventoAPI from "../API/Servicios/EventoAPI";
import SourceAPI from "../API/SourceAPI";
import Combo from "../Component/Combo";
import FormReducer from "../Component/FormReducer";
import SpinnerBox from "../Component/SpinnerBox";
import TextBox from "../Component/TextBox";
import EventoValidacion from "./DetalleEVentoValidation";
import NumberBox from "../Component/NumberBox";
//import Calendario from "../Component/Calendario";

const DetalleEVento = ({ item, cerrar, servicio, loading, tipoevento }) => {
    const api = EventoAPI();
    const source = SourceAPI();
    const defaultEntity = () => ({
        entity: {
            id_evento: 0,
            nombre: '',
            lugar: '',
            hotel: '',
            dia_inicio: '',
            dia_fin: '',
            anio: '',
            transporte: [],
            id_tipo_evento: tipoevento
        }
    });
    const [meses, setMeses] = useState([]);
    const [confirmar, setConfirmar] = useState(false);
    const [mensaje, setMensaje] = useState(null);
    const [enviado, setEnviado] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [tipoTraslado, setTipoTraslado] = useState([]);
    const tipoHorarioEvento = [
        { id: '1', nombre: 'Aeropuerto - Hotel' },
        { id: '2', nombre: 'Hotel - Aeropuerto' }
    ];
    const tipoHorarioTour = [
        { id: '3', nombre: 'Hotel - Destino' },
        { id: '4', nombre: 'Destino - Hotel' }
    ]
    const [tipoEliminar, setTipoEliminar] = useState('');
    const [id, setId] = useState(0);
    const [indice, setIndice] = useState(0);
    const [idx, setIdx] = useState(0);
    const [{ entity }, dispatch] = useReducer(FormReducer, {}, defaultEntity);

    useEffect(() => {
        sources();
        if (item?.id_evento > 0) {
            cargar();
        }
    }, []);

    const cargar = async () => {
        loading(true);
        let result = await api.detalle(item.id_evento);
        const fecha = new Date().toDateString();
        result.transporte?.forEach((item) => {
            item.horario = result.horario.filter(h => h.id_tipo_transporte === item.id_tipo_transporte);
            item.horario.map((row) => (
                row.fecha_hora = new Date(fecha + ' ' + row.hora)
            ))
        });
        result.item.transporte = result.transporte;
        dispatch({ type: 'update', value: result.item });
        loading(false);
    };

    const sources = async () => {
        const meses = await source.obtener('meses');
        setMeses(meses.items);
        const tipoTraslado = await source.obtener('tipoTraslado');
        setTipoTraslado(tipoTraslado.items);
    };

    const actualizar = (value, name) => {
        dispatch({ type: 'update', value: value, name: name });
    };

    const guardar = async () => {
        loading(true);
        setEnviado(true);
        let valido = false;
        if (servicio === 1) {
            valido = await EventoValidacion.guardarEvento.isValid(entity);
        }
        else {
            valido = await EventoValidacion.guardarTour.isValid(entity);
        }

        if (valido) {
            const result = await api.guardar(entity);
            console.log(result);
            if (result.id > 0) {
                cerrar();
            }
        }
        loading(false);
    };

    const eliminar = async () => {
        loading(true);
        let result = { id: 0 };
        if (tipoEliminar === 'e') {
            result = await api.eliminar(entity.id_evento);
            if (result.id > 0) {
                cerrar();
            }
        }

        if (tipoEliminar === 't') {
            if (id === 0) {
                const newValue = produce(entity.transporte, (draft) => {
                    draft.splice(indice, 1);
                });

                actualizar(newValue, 'transporte');
            }
            else {
                result = await api.eliminarTransporte(id);
                if (result.id > 0) {
                    cargar();
                }
            }
        }

        if (tipoEliminar === 'h') {
            if (id === 0) {
                const newValue = produce(entity.transporte, (draft) => {
                    draft[indice]['horario'].splice(idx, 1);
                });

                actualizar(newValue, 'transporte');
            }
            else {
                result = await api.eliminarHorario(id);
                if (result.id > 0) {
                    cargar();
                }
            }
        }
        loading(false);
    };

    const confirmarEliminar = (tipo, id, index, idx) => {
        setTipoEliminar(tipo);
        setId(id);
        setIndice(index);
        setIdx(idx);
        setMensaje("¿Eliminar el registro?");
        setConfirmar(true);
    };

    const agregarTransporte = () => {
        const newItem = {
            id_tipo_transporte: 0,
            nombre: '',
            id_evento: entity.id_evento,
            id_tipo_traslado: null,
            precio: null,
            horario: []
        };
        const newValue = produce(entity.transporte, (draft) => {
            draft.push(newItem);
        });
        actualizar(newValue, 'transporte');
    };

    const actualizarTransporte = (value, name, index) => {
        const newValue = produce(entity.transporte, (draft) => {
            draft[index][name] = value;
        });

        actualizar(newValue, 'transporte');
    };

    const agregarHorario = (index) => {
        const newValue = produce(entity.transporte, (draft) => {
            draft[index]['horario'].push({ id_horario: 0 });
        });

        actualizar(newValue, 'transporte');
    };

    const actualizarHorario = (value, name, index, indexh) => {
        const newValue = produce(entity.transporte, (draft) => {
            draft[index]['horario'][indexh][name] = value;

            if (name === 'fecha_hora') {
                draft[index]['horario'][indexh]['hora'] = value.toLocaleTimeString();
            }
        });

        actualizar(newValue, 'transporte');
    };

    return (
        <div className="row">
            <TabView activeIndex={activeTabIndex} onChange={(e) => setActiveTabIndex(e.index)}>
                <TabPanel header={servicio === 1 ? 'Evento' : 'Tour'}>
                    <div className="row">
                        <TextBox label={servicio === 1 ? 'Evento' : 'Tour'} name="nombre" value={entity.nombre} lg={12} md={12} sm={12}
                            onChange={actualizar} requerido={enviado} />
                        {servicio === 1 &&
                            <>
                                <TextBox label={'Lugar'} name="lugar" value={entity.lugar} lg={6} md={6} sm={6}
                                    onChange={actualizar} requerido={enviado} />
                                <TextBox label={'Hotel'} name="hotel" value={entity.hotel} lg={6} md={6} sm={6}
                                    onChange={actualizar} requerido={enviado} />
                            </>
                        }
                        <SpinnerBox label={'Dia Inicio'} name="dia_inicio" value={entity?.dia_inicio} requerido={enviado}
                            onChange={actualizar} lg={6} md={6} sm={6} min={1} max={31} />
                        <Combo label={'Mes Inicio'} name="mes_inicio" value={entity?.mes_inicio}
                            opciones={meses} nameLabel="mes" nameValue="id_mes" requerido={enviado}
                            onChange={actualizar} lg={6} md={6} sm={6} />
                        <SpinnerBox label={'Dia Fin'} name="dia_fin" value={entity?.dia_fin} requerido={enviado}
                            onChange={actualizar} lg={6} md={6} sm={6} min={1} max={31} />
                        <Combo label={'Mes Fin'} name="mes_fin" value={entity?.mes_fin}
                            opciones={meses} nameLabel="mes" nameValue="id_mes"
                            onChange={actualizar} lg={6} md={6} sm={6} requerido={enviado} />
                        <SpinnerBox label={'Año'} name="anio" value={entity?.anio} requerido={enviado}
                            onChange={actualizar} lg={6} md={6} sm={6} min={1} max={9999} />
                    </div>
                </TabPanel>
                <TabPanel header="Transporte">
                    <div className="row">
                        <div className="form-group mb-2 botones" style={{ textAlign: 'end' }}>
                            <Button icon="pi pi-plus" title="Agregar transporte" onClick={agregarTransporte} className="btn-azul" />
                        </div>
                        {entity.transporte?.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <hr />
                                    <div className="form-group mb-2 botones" style={{ textAlign: 'end' }}>
                                        <Button icon="pi pi-trash" title="Eliminar Evento" className="p-button-danger"
                                            onClick={() => confirmarEliminar('t', item.id_tipo_transporte, index, 0)} />
                                    </div>
                                    <Combo label={'Tipo de Traslado'} name="id_tipo_traslado" value={item.id_tipo_traslado}
                                        opciones={tipoTraslado} nameLabel="nombre" nameValue="id_tipo_traslado" requerido={enviado}
                                        onChange={(value, name) => actualizarTransporte(value, name, index)} lg={3} md={3} sm={12} />
                                    <TextBox label={'Nombre'} name="nombre" value={item.nombre} lg={6} md={6} sm={12} type="text"
                                        onChange={(value, name) => actualizarTransporte(value, name, index)} requerido={enviado} />
                                    <NumberBox label={'Precio'} name="precio" value={item.precio} lg={3} md={3} sm={12} mode="decimal" max={2}
                                        onChange={(value, name) => actualizarTransporte(value, name, index)} requerido={enviado} />
                                    <table className="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <th>Tipo de Horario</th>
                                                {/* <th>Hora de Salida de Transporte</th> */}
                                                <th>
                                                    <div className="form-group botones" style={{ textAlign: 'center' }}>
                                                        <Button icon="pi pi-plus" title="Agregar horario" onClick={() => agregarHorario(index)} />
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.horario.map((row, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            {servicio === 1 ?
                                                                <Combo name="id_tipo_traslado" value={row.id_tipo_traslado}
                                                                    opciones={tipoHorarioEvento} nameLabel="nombre" nameValue="id" requerido={enviado}
                                                                    onChange={(value, name) => actualizarHorario(value, name, index, i)} />
                                                                :
                                                                <Combo name="id_tipo_traslado" value={row.id_tipo_traslado}
                                                                    opciones={tipoHorarioTour} nameLabel="nombre" nameValue="id" requerido={enviado}
                                                                    onChange={(value, name) => actualizarHorario(value, name, index, i)} />
                                                            }
                                                        </td>
                                                        {/* <td>
                                                            <Calendario name="fecha_hora" requerido={enviado} esHora={true} value={row.fecha_hora}
                                                                onChange={(value, name) => actualizarHorario(value, name, index, i)} />
                                                        </td> */}
                                                        <td style={{ textAlign: 'center' }} className="botones">
                                                            <Button icon="pi pi-trash" title="Eliminar Horario" className=" p-button-danger"
                                                                onClick={() => confirmarEliminar('h', row.id_horario, index, i)} />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </Fragment>
                            );
                        })}
                    </div>
                </TabPanel>
            </TabView>
            <footer className="row py-4 mt-5">
                <div className="fcol-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                    <Button label="Cancelar" className="p-button-secondary p-button-outlined m-1"
                        onClick={cerrar} />
                    {entity.id_evento > 0 &&
                        <Button label="Eliminar" className="p-button-danger p-button-outlined m-1"
                            onClick={() => confirmarEliminar('e', entity.id_evento, 0, 0)} />
                    }
                    <Button label="Guardar" className="btn-outline-primary p-button-outlined m-1"
                        onClick={guardar} />
                </div>
            </footer>
            <ConfirmDialog visible={confirmar} onHide={() => setConfirmar(false)} message={mensaje}
                header="Confirmar" icon="pi pi-exclamation-triangle" accept={eliminar} reject={() => setConfirmar(false)} />
        </div >
    );
};

export default DetalleEVento;