import React from "react";
import { InputTextarea } from 'primereact/inputtextarea';

const TextArea = ({ name, label, value, onChange, lg, md, sm, requerido, rows, maxLength }) => {
    const change = (e) => {
        const value = e.target.value;
        onChange(value, name);
    };

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-column">
                <label htmlFor={name} className='etiquetas'>
                    {requerido &&
                        <span style={{ color: 'red' }}>* </span>
                    }
                    {label} ({(value?.length ?? 0)}/{(maxLength ?? 250)})
                </label>
                <InputTextarea name={name} value={value} onChange={change} rows={rows} maxLength={maxLength}
                    className={requerido && ((value ?? '') === '' || value === null) ? 'p-invalid block' : ''} />
            </div>
        </div>
    );
};

export default TextArea;