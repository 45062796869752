/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import EventoAPI from "../API/Servicios/EventoAPI";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import DetalleEVento from "./DetalleEvento";
import { Toast } from 'primereact/toast';
import { Constantes } from "../Shared/Constantes";

const Tours = ({ loading }) => {
    const api = EventoAPI();
    const [datos, setDatos] = useState([]);
    const [verDetalle, setVerDetalle] = useState(false);
    const [evento, setEvento] = useState({});
    const toastBR = useRef(null);

    useEffect(() => {
        cargar();
    }, []);

    const cargar = async () => {
        loading(true);
        let result = await api.obtener(Constantes.servicio.Tour);
        setDatos(result.items);
        loading(false);
    };

    const ver = (item) => {
        setEvento(item);
        setVerDetalle(true);
    };

    const templateEditButton = (item) => {
        return (
            <div className="botones">
                <Button type="button" icon="pi pi-pencil" onClick={() => ver(item)} className="btn-azul" />
            </div>
        );
    };

    const templateFechaInicio = (item) => {
        return (
            <span>{item.dia_inicio} de {item.nombre_mes_inicio}</span>
        );
    };

    const templateFechaFin = (item) => {
        return (
            <span>{item.dia_fin} de {item.nombre_mes_fin}</span>
        );
    };

    const cerrar = async () => {
        showBottomRight();
        setVerDetalle(false);
        await cargar();
    };

    const showBottomRight = () => {
        toastBR.current.show({ severity: 'success', summary: 'Operacion Exitosa', detail: 'Operacion realizada correctamente.', life: 3000 });
    }

    const templateHeaderButton = () => {
        return (
            <div className="botones">
                <Button type="button" icon="pi pi-plus" onClick={() => ver({ id_evento: 0 })} className="btn-azul" />
            </div>
        );
    }

    return (
        <>
            <DataTable value={datos} responsiveLayout="scroll"
                paginator={true} rows={10} emptyMessage="Sin datos para mostrar"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50]}>
                <Column field="nombre" header="Tour" />
                <Column header="Inicio" body={templateFechaInicio} />
                <Column header="Fin" body={templateFechaFin} />
                <Column field="anio" header="Año" className="center" />
                <Column body={templateEditButton} header={templateHeaderButton} />
            </DataTable>
            <Sidebar visible={verDetalle} position="right" fullScreen className="p-sidebar-lg"
                onHide={() => setVerDetalle(false)} dismissable={false}>
                <DetalleEVento item={evento} cerrar={cerrar} servicio={2} loading={loading} tipoevento={2} />
            </Sidebar>
            <Toast ref={toastBR} position="bottom-right" />
        </>
    );
};

export default Tours;