import ServiceFactory from "../ServiceFactory";
const controller = "/apiAerolinea";

const AerolineaAPI = () => {
    const services = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        obtener: async () => services.get(`${controller}/obtener`),
        detalle: async (id) => services.get(`${controller}/detalle/${id}`),
        eliminar: async (id) => services.delete(`${controller}/eliminar/${id}`),
        guardar: async (params) => services.post(`${controller}/guardar`, params)
    }
};

export default AerolineaAPI;