import ServiceFactory from "../ServiceFactory";
const controller = "/apiTraslado";

const TrasladoAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        obtener: async (id) => service.get(`${controller}/obtener/${id}`),
        traslados: async (id) => service.get(`${controller}/traslados/${id}`),
        obtenerEvento: async (id) => service.get(`${controller}/obtenerEvento/${id}`),
        servicio: async (id) => service.get(`${controller}/servicio/${id}`),
        transportacion: async (id) => service.get(`${controller}/transportacion/${id}`),
        guardar: async (params) => service.post(`${controller}/guardar`, params),
        buscar: async (folio) => service.get(`${controller}/buscar/${folio}`),
        cargar: async (id, formData) => service.upload(`${controller}/cargar/${id}`, formData),
        actualizar: async (id, params) => service.put(`${controller}/actualizar/${id}`, params),
        eliminar: async (id) => service.delete(`${controller}/eliminar/${id}`),
        aceptar: async (id) => service.put(`${controller}/aceptar/${id}`, {}),
        rechazar: async (id) => service.put(`${controller}/rechazar/${id}`),
        abordaje: async(id, params)=> service.post(`${controller}/abordaje/${id}`, params)
    }
};

export default TrasladoAPI;