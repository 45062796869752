/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useReducer, useState } from "react";
import SeguridadAPI from "../API/Seguridad/SeguridadAPI";
import SourceAPI from "../API/SourceAPI";
import Combo from "../Component/Combo";
import FormReducer from "../Component/FormReducer";
import TextBox from "../Component/TextBox";
import UsuarioValidacion from "./DetalleUsuarioValidation";

const DetalleUsuario = ({ item, save, cerrar, loading }) => {
    const api = SeguridadAPI();
    const source = SourceAPI();
    const defaultEntity = () => ({
        entity: {
            id_usuario: 0,
            nombre: '',
            correo: '',
            id_rol: 0,
            password: ''
        }
    });
    const [roles, setRoles] = useState([]);
    const [confirmar, setConfirmar] = useState(false);
    const [mensaje, setMensaje] = useState(null);
    const [enviado, setEnviado] = useState(false);

    const [{ entity }, dispatch] = useReducer(FormReducer, {}, defaultEntity);

    useEffect(() => {
        sources();
        if (item?.id_usuario > 0) {
            cargar();
        }
    }, []);

    const cargar = async () => {
        loading(true);
        let result = await api.detalle(item.id_usuario);
        result.item.password = '';
        dispatch({ type: 'update', value: result.item });
        loading(false);
    };

    const sources = async () => {
        const result = await source.obtener('roles');
        setRoles(result.items);
    };

    const actualizar = (value, name) => {
        dispatch({ type: 'update', value: value, name: name });
    };

    const guardar = async () => {
        loading(true);
        setEnviado(true);
        const valido = await UsuarioValidacion.guardar.isValid(entity);
        if (valido) {
            const result = await api.guardar(entity);
            if (result.id > 0) {
                save();
            }
        }
        loading(false);
    };

    const confirmarEliminar = () => {
        setMensaje("¿Eliminar el registro?");
        setConfirmar(true);
    }

    const eliminar = async () => {
        loading(true);
        const result = await api.eliminar(entity.id_usuario);
        if (result.id > 0) {
            save();
        }
        loading(false);
    };

    return (
        <div className="row">
            <h5>Usuario</h5>
            <hr />
            <TextBox label={'Usuario'} name="nombre" value={entity.nombre} lg={12} md={12} sm={12}
                onChange={actualizar} requerido={enviado} type="text" />
            <TextBox label={'Correo'} name="correo" value={entity.correo} lg={12} md={12} sm={12}
                onChange={actualizar} requerido={enviado} type="email" />
            <Combo label={'Perfil'} name="id_rol" value={entity?.id_rol}
                opciones={roles} nameLabel="rol" nameValue="id_rol" requerido={enviado}
                onChange={actualizar} lg={6} md={6} sm={6} />
            <TextBox label={'Contraseña'} name="password" value={entity.password} lg={6} md={6} sm={6}
                onChange={actualizar} requerido={false} type="password" />
            <footer className="row py-4 mt-5">
                <div className="col-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                    <Button label="Cancelar" className="p-button-secondary p-button-outlined m-1"
                        onClick={cerrar} />
                    {entity.id_usuario > 0 &&
                        <Button label="Eliminar" className="p-button-danger p-button-outlined m-1"
                            onClick={confirmarEliminar} />
                    }
                    <Button label="Guardar" className="p-button-outlined m-1"
                        onClick={guardar} disabled={enviado} />
                </div>
            </footer>
            <ConfirmDialog visible={confirmar} onHide={() => setConfirmar(false)} message={mensaje}
                header="Confirmar" icon="pi pi-exclamation-triangle" accept={eliminar} reject={() => setConfirmar(false)} />
        </div>
    );
};

export default DetalleUsuario;