import React from 'react';
import { InputNumber } from 'primereact/inputnumber';

const SpinnerBox = ({ name, label, value, onChange, lg, md, sm, min, max, requerido }) => {
    const change = (e) => {
        const propertyName = name;
        const value = e?.value;
        onChange(value, propertyName);
    };

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-column botones">
                <label htmlFor={name} className='etiquetas'>{label}</label>
                <InputNumber inputId='horizontal' id={name} name={name} value={value}
                    showButtons buttonLayout='horizontal' step={1} min={min} max={max}
                    decrementButtonClassName={'btn-morado'} incrementButtonClassName={'btn-azul'}
                    incrementButtonIcon={'pi pi-plus'} decrementButtonIcon={'pi pi-minus'} mode="decimal" useGrouping={false}
                    onChange={change} className={requerido && (value ?? null) === null ? 'p-invalid block' : ''} />
            </div>
        </div>
    );
};

export default SpinnerBox;