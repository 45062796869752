import * as yup from "yup";

const guardar = yup.object().shape({
    nombre: yup.string().required()
});

const AerolineaValidacion = {
    guardar
};

export default AerolineaValidacion;