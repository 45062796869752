import React from "react";
import { Button } from "primereact/button";
import { useState } from "react";
import SeguridadAPI from "../API/Seguridad/SeguridadAPI";
import { Constantes } from "../Shared/Constantes";
import PersistedState from "../Hook/PersistedState";
import User from "./User";
import produce from "immer";
import TextBox from "../Component/TextBox";
import Alert from "../Component/Alert";

const Login = ({ loading }) => {
    const [login, setLogin] = useState({ username: '', password: '' });
    const api = SeguridadAPI();
    const [, setUser] = PersistedState(Constantes.userStorage, new User());
    const [enviado, setEnviado] = useState(false);
    const [error, setError] = useState(false);

    const onLogin = async () => {
        loading(true);
        setEnviado(true);
        if (login.username === '') {
            return;
        }

        if (login.password === '') {
            return;
        }

        const result = await api.login(login);
        setError(!result?.logueado);
        if (result?.logueado) {
            setUser(result)
            localStorage.setItem('UsuarioNombre', result.nombre);
            window.location.reload(true);
        }
        loading(false);
    };

    const actualizar = (value, name) => {
        const newLogin = produce(login, draft => {
            draft[name] = value;
        });

        setLogin(newLogin);
    };

    return (
        <div className="container">
            <div className="row">
                {error &&
                    <Alert tipo="error" text="Usuaruo y/o contraseña incorrecta" />
                }
                <div className="col-lg-6 col-md-4 col-sm-12">
                </div>
                <div className="col-lg-6 col-md-8 col-sm-12 container-min mt-3">
                    <div className="row">
                        <TextBox label={'Usuario'} name="username" value={login?.username} requerido={enviado}
                            onChange={actualizar} type="text" lg={12} md={12} sm={12} />
                        <TextBox label={'Contraseña'} name="password" value={login?.password} requerido={enviado}
                            onChange={actualizar} type="password" />
                        <div className="col-lg-12 col-md-12 col-sm-12 mt-2 botones" style={{ textAlign: 'end' }}>
                            <Button label="Entrar" icon="pi pi-sign-in" className="btn-verde"
                                onClick={onLogin} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;