import ServiceFactory from "../ServiceFactory";
const controller = "/apiAbordar";

const OperacionAPI = () => {
    const services = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        cargar: async (folio) => services.get(`${controller}/cargar/${folio}`),
        abordar: async (id, tipo) => services.put(`${controller}/abordar/${id}/${tipo}`, {})
    }
};

export default OperacionAPI;


