import React from "react";
import { InputNumber } from "primereact/inputnumber";

const NumberBox = ({ name, label, value, onChange, lg, md, sm, type, requerido, mode, min, max }) => {
    const change = (e) => {
        const value = e.value;
        onChange(value, name);
    };

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-column">
                <label htmlFor={name} className='etiquetas'>
                    {requerido &&
                        <span style={{ color: 'red' }}>* </span>
                    }
                    {label}
                </label>
                <InputNumber type={type} id={name} name={name} value={value} mode={mode} minFractionDigits={min} maxFractionDigits={max}
                    onValueChange={change} className={requerido && ((value ?? '') === '' || value === null) ? 'p-invalid block' : ''} />
            </div>
        </div>
    );
};

export default NumberBox;