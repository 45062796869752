import React from 'react';
import { Calendar } from 'primereact/calendar';

const Calendario = ({ name, label, value, onChange, lg, md, sm, esHora, requerido, minDate, maxDate, disabled }) => {

    const change = (e) => {
        e.preventDefault();
        const name = e.target.id;
        const value = e.target.value;
        onChange(value, name);
    }

    return (
        <div className={`form-group col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-column">
                <label htmlFor={name} className='etiquetas'>
                    {requerido &&
                        <span style={{color: 'red'}}>* </span>
                    }
                    {label}
                </label>
                {esHora ?
                    <Calendar id={name} name={name} value={value} onChange={change} timeOnly hourFormat="12"
                        className={requerido && ((value ?? null) === null) ? 'p-invalid block' : ''} />
                    :
                    <Calendar id={name} name={name} value={value} onChange={change} dateFormat="dd/mm/yy" disabled={disabled}
                        className={requerido && ((value ?? null) === null) ? 'p-invalid block' : ''} minDate={minDate} maxDate={maxDate} />
                }
            </div>
        </div>
    );
}

export default Calendario;